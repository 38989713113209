import React, { useEffect } from 'react'

export default function Toaster(props) {

    useEffect(() => {
        setTimeout(() => {
            props.close({
                type: "",
                message: "",
                show: 0
            })
        }, 1500)
    }, [])
    return (
        <>
            <div class="toast-container position-fixed top-0 end-0 p-3" style={{zIndex:"9999999999"}}>
                <div class={`toast ${props.show === 1 ? "show" : ""} text-dark ${props.type.trim().toLowerCase() === "alert".trim().toLowerCase() ? "bg-danger" : props.type.trim().toLowerCase() === "success".trim().toLowerCase() ? "bg-success" : "bg-warning"}`}>
                    <div class="toast-body">
                        <div class="d-flex gap-4">
                            <div class="d-flex flex-grow-1 align-items-center">
                                <span class="fw-semibold text-white">{props.message}</span>
                                <button type="button" class="btn-close btn-close-sm btn-close-black ms-auto" onClick={() => {
                                    props.close({
                                        type: "",
                                        message: "",
                                        show: 0
                                    });
                                }}></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
