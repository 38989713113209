import React, { useEffect, useState } from 'react'
import Toaster from '../../components/Toaster'
import { Link, useNavigate } from 'react-router-dom'
import toCamelCase from '../../assests/js/CamleCase'
import UploadAssessment from '../../api/Student/UploadAssessment'
import GetStudentList from '../../api/Student/GetStudentList'
import GetClassList from '../../api/Class/GetClassList'
import GetSchoolListByAdmin from '../../api/User/GetSchoolListByAdmin'
import downlaodgif from "../../assests/images/downloadgif.gif"
import GetStudentListForSevere from '../../api/Severe/GetStudentListForSevere'

export default function SevereList() {
    let [studentList, setStudentList] = useState([])
    let [studentListTemp, setStudentListTemp] = useState([])
    let [noOfRows, setNoOfRows] = useState(10)
    let [currentPage, setCurrentPage] = useState(1)
    // let [schoolSession, setSchoolSession] = useState([])
    let [schoolList, setSchoolList] = useState([])
    let [classList, setClassList] = useState([])
    let [showPrintCart, setShowPrintCard] = useState(0)
    let [studentData, setStudentData] = useState([])
    let [studentPdf, setStudentPdf] = useState(0)
    let [currentRow, setCurrentRow] = useState('')
    let [savePdf, setSavePdf] = useState([])
    let navigate = useNavigate()
    let [loader, setLoader] = useState(0)
    let [activeNumber, setActiveNumber] = useState(1)
    let [studentFileData, setStudentFileData] = useState({
        studentId: "",
        IsRegular: true,
        formFile: ""
    })
    let [toaster, setToaster] = useState({
        type: "",
        message: "",
        show: 0
    })

    let handleSavePdfTemp = (id) => {
        let allSavePdf = window.sessionStorage.getItem("SavePdf") ? JSON.parse(window.sessionStorage.getItem("SavePdf")) : []
        if (allSavePdf.length !== 0) {
            allSavePdf.push(id)
            setSavePdf(allSavePdf)
            window.sessionStorage.setItem("SavePdf", JSON.stringify(allSavePdf))
        }
        else {
            allSavePdf.push(id)
            window.sessionStorage.setItem("SavePdf", JSON.stringify(allSavePdf))
        }
    }

    let getData = async () => {

        let responsiveSchool = await GetSchoolListByAdmin()
        // let responseClass = await GetClassList()
        if (responsiveSchool.status === 1) {
            setSchoolList(responsiveSchool.responseValue)
        }
        // if (responseClass.status === 1) {
        //     setClassList(responseClass.responseValue)
        // }

    }

    let getClassData = async (id) => {
        let responseClass = await GetClassList(id)
        if (responseClass.status === 1) {
            setClassList(responseClass.responseValue)
        }
    }

    let getDataStudent = async (school = "", classed = "") => {
        let url = ""
        let id = window.sessionStorage.getItem("userData") ? JSON.parse(window.sessionStorage.getItem("userData")).responseValue.id : 1
        if (school !== "" && classed !== "") {
            url = window.BaseUrl + `/api/Users/GetStudentListForSevere?SchoolId=${school}&Class=${classed}&UserId=${id}`
        }
        else if (school !== "") {
            url = window.BaseUrl + `/api/Users/GetStudentListForSevere?SchoolId=${school}&UserId=${id}`
        }
        else if (classed !== "") {
            url = window.BaseUrl + `/api/Users/GetStudentListForSevere?Class=${classed}&UserId=${id}`

        }
        else {
            url = window.BaseUrl + `/api/Users/GetStudentListForSevere?UserId=${id}`

        }
        let response = await GetStudentListForSevere(url)
        if (response.status === 1) {
            setStudentList(response.responseValue)
            setStudentListTemp(response.responseValue.slice(0, noOfRows))
        }
        else {
            setStudentList([])
            setStudentListTemp([])
        }
    }

    let handleRows = (e) => {
        let noofrows = parseInt(e.target.value)
        setStudentListTemp(studentList.slice(0, 1 * noofrows))
        setCurrentPage(1)
        setNoOfRows(noofrows)
    }



    let handleChnage = (e) => {
        let name = e.target.name
        let value = e.target.value
        getClassData(value)

    }

    let handleFilter = () => {
        let schoolId = document.getElementById("schooldropdown").value
        let classId = document.getElementById("classdropdown").value
        if (schoolId.toString() !== "-1" && classId.toString() !== "-1") {
            getDataStudent(schoolId, classId.toString())
        }
        else if (schoolId.toString() !== "-1") {
            getDataStudent(schoolId)
        }
        else if (classId.toString() !== "-1") {
            getDataStudent(classId.toString())

        }
        else {

        }
    }

    let handleCardPrint = (val) => {
        setShowPrintCard(1)
        setStudentData(val)
    }

    let handleSearch = (e) => {
        let valuesearch = e.target.value.trim().toLowerCase();
        if (valuesearch.length > 0) {
            let data = studentList.filter(obj => {
                return Object.values(obj).some(value => {
                    return value != null && value.toString().toLowerCase().includes(valuesearch);
                });
            });
            if (data.length !== 0) {
                setStudentListTemp(data.filter(value => value !== undefined).splice(0, noOfRows))
            }
            else {
                setStudentListTemp([])
            }
        }
        else {
            setStudentListTemp(studentList.splice(0, noOfRows))

        }

    }



    let handlePagination = (value) => {
        let nextPage = currentPage;

        if (value === 0) {
            nextPage = Math.max(currentPage - 1, 1); // Ensure nextPage is at least 1
        } else if (value === 1) {
            nextPage = currentPage;
        } else if (value === 2) {
            nextPage = currentPage + 1;
        } else if (value === 3) {
            nextPage = Math.min(currentPage + 1, Math.ceil(studentList.length / noOfRows));
        }

        setCurrentPage(nextPage);
        setActiveNumber(nextPage);

        const startIndex = (nextPage - 1) * noOfRows;
        const endIndex = nextPage * noOfRows;
        setStudentListTemp(studentList.slice(startIndex, endIndex));

    }


    let handleSaveFile = async () => {
        let file = new FormData()
        file.append("formFile", studentFileData.formFile)
        let response = await UploadAssessment(studentFileData, file)
        if (studentFileData.formFile.length !== 0) {
            if (response.status === 1) {
                setToaster({
                    type: "success",
                    message: "Date Saved !!",
                    show: 1
                })

            }
            else {
                setToaster({
                    type: "alert",
                    message: response.message,
                    show: 1
                })
            }
        }
        else {
            setToaster({
                type: "alert",
                message: "Please Select File!!",
                show: 1
            })
        }

    }

    useEffect(() => {
        getData()
        getDataStudent()
        let allSavePdf = window.sessionStorage.getItem("SavePdf") ? JSON.parse(window.sessionStorage.getItem("SavePdf")) : []
        setSavePdf(allSavePdf)
    }, [])

    return (
        <>
            <div class="row g-3 mb-4 align-items-center justify-content-between">
                <div class="col-auto">
                    <h1 class="app-page-title mb-0">Severe List</h1>
                </div>

            </div>
            <div class="row g-3 mb-4 align-items-center justify-content-between">
                <div class="col-auto">
                    <div class="page-utilities">
                        <div class="d-flex flex-column flex-md-row justify-content-between gap-5">
                            <div className='row g-2 justify-content-start justify-content-md-end align-items-center'>
                                <div class="col-auto">

                                    {/* <select class="form-select w-auto" id="sessiondropdown">
                                        <option selected value="-1">Select Session</option>
                                        {
                                            schoolSession && schoolSession.map((val, ind) => {
                                                return (
                                                    <option value={val.id}>{val.name}</option>

                                                )
                                            })
                                        }
                                        

                                    </select> */}
                                </div>

                                <div class="col-auto">

                                    <select class="form-select w-auto" id="schooldropdown" onChange={handleChnage}>
                                        <option selected value="-1">Select School</option>
                                        {
                                            schoolList && schoolList.map((val, ind) => {
                                                return (
                                                    <option value={val.id}>{toCamelCase(val.name)}</option>

                                                )
                                            })
                                        }

                                    </select>
                                </div>

                                <div class="col-auto">

                                    <select class="form-select w-auto" id="classdropdown">
                                        <option selected value="-1">Select Class</option>
                                        {
                                            classList && classList.map((val, ind) => {
                                                return (
                                                    <option value={val.class}>{val.class}</option>

                                                )
                                            })
                                        }

                                    </select>
                                </div>
                                <div class="col-auto">
                                    <button type="button" class="btn app-btn-secondary" onClick={handleFilter}>Filter</button>
                                </div>
                            </div>

                            <div className='row g-2 justify-content-start justify-content-md-end align-items-center'>

                            </div>


                            {/* <div class="col-auto">
                                <Link class="btn app-btn-secondary" to="#">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-download me-1"
                                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                            d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                        <path fill-rule="evenodd"
                                            d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                    </svg>
                                    Download CSV
                                </Link>
                            </div> */}


                        </div>
                    </div>
                </div>
            </div>

            <div className=''>
                <div class="app-card app-card-orders-table shadow-sm mb-5">
                    <div class="app-card-body p-3">
                        <div class="table-responsive">
                            <div class="d-flex flex-row justify-content-between pb-2 me-2">
                                <div className='d-flex flex-row gap-5'>
                                    <div>
                                        <label>Select Rows</label>
                                        <select class="form-select w-auto" onChange={handleRows}>
                                            <option value="10" className='selected'>10</option>
                                            <option value="15" >15</option>
                                            <option value="5" >5</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label>No. Of Pages</label>
                                        <div class="form-control w-auto">{Math.round(studentList.length / noOfRows)}</div>
                                    </div>
                                </div>
                                <div class="table-search-form row gx-1 align-items-center">
                                    <div class="col-auto">
                                        <input type="text" id="search-orders_" name="searchData"
                                            class="form-control search-orders" placeholder="Search" onChange={handleSearch} />
                                    </div>
                                    {/* <div class="col-auto">
                                            <button type="button" class="btn app-btn-secondary">Search</button>
                                        </div> */}
                                </div>

                            </div>
                            <div class="table-responsive" style={{ maxHeight: "480px" }}>
                                <table class="table table-striped  table-responsive app-table-hover mb-0 text-left">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th class="cell">Student Id</th>
                                            <th class="cell">Student Name</th>
                                            <th class="cell">Class</th>
                                            <th class="cell">Section</th>
                                            <th class="cell">Gender</th>
                                            <th class="cell">School Name</th>
                                            {/* <th class="cell">Contact Number</th> */}
                                            {/* <th class="cell">Id Card</th>
                                            <th class="cell">Physical Report</th>
                                            <th class="cell">Psychology Report</th> */}
                                            <th className='cell'>Severeness</th>
                                            <th className='cell'>Problem Description</th>
                                            {window.roleId === 1 ? <th class="cell">Action</th> : ""}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            studentListTemp.map((val, ind) => {
                                                return (
                                                    <tr >
                                                        <td class="cell">{ind + 1}</td>
                                                        <td class="cell">{val.student_id}</td>
                                                        <td class="cell"><span class="truncate">{toCamelCase(val.first_name)} {val.last_name !== undefined ? toCamelCase(val.last_name) : ""}</span></td>
                                                        <td class="cell">{val.class}</td>
                                                        <td class="cell">{val.section}</td>
                                                        <td class="cell">{toCamelCase(val.gender)}</td>
                                                        <td class="cell">{toCamelCase(val.school_name)}
                                                        </td>
                                                        <td class="cell text-wrap"> 
                                                            {toCamelCase(val.severeDetails.length !==0?  val.severeDetails.substring(0, val.severeDetails.length - 1):"")}
                                                        </td>
                                                        <td class="cell">{val.problemDescription}</td>
                                                        {/* <td class="cell">{val.contact_number}</td> */}
                                                        {/* <td class="cell">
                                                            <div onClick={() => { handleCardPrint(val); setLoader(1); }} to={"/studentcard/?studentId=" + val.student_id + "&studentName=" + val.first_name + " " + val.last_name + "&class=" + val.class + "&school=" + val.school_name}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "red" }} width="22" height="22" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16">
                                                                    <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
                                                                </svg>
                                                            </div>
                                                        </td>
                                                        <td class="cell">
                                                            <div onClick={() => { setStudentData(val); setStudentPdf(1); setLoader(1); setCurrentRow(val.student_id); handleSavePdfTemp(val.student_id) }} to={"/studentcard/?studentId=" + val.student_id + "&studentName=" + val.first_name + " " + val.last_name + "&class=" + val.class + "&school=" + val.school_name}>
                                                                {
                                                                    loader === 0 ?
                                                                        <svg xmlns="http://www.w3.org/2000/svg" style={{ color: savePdf.filter(value => value === val.student_id).length === 0 ? "red" : "green" }} width="22" height="22" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16">
                                                                            <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
                                                                        </svg> :

                                                                        currentRow === val.student_id ?
                                                                            <div className='' style={{ width: "80px" }}>
                                                                                <img src={downlaodgif} alt="downlaod loader" width={"80px"} />
                                                                            </div> : <svg xmlns="http://www.w3.org/2000/svg" style={{ color: savePdf.filter(value => value === val.student_id).length === 0 ? "red" : "green" }} width="22" height="22" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16">
                                                                                <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
                                                                            </svg>


                                                                }

                                                            </div>
                                                        </td> */}
                                                        {/* <td>
                                                            <div className='d-flex flex-row gap-4'>

                                                                {
                                                                    val.fileUrl !== undefined && val.fileUrl.length !== 0 ?


                                                                        <Link target='_blank' to={val.fileUrl}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "green" }} width="22" height="22" fill="currentColor" class="bi bi-card-text" viewBox="0 0 16 16">
                                                                                <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2z" />
                                                                                <path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8m0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5" />
                                                                            </svg>
                                                                        </Link>
                                                                         :
                                                                         ""
                                                                        
                                                                }

                                                            </div>

                                                        </td> */}
                                                        {window.roleId === 1 ?
                                                            <td class="cell">
                                                                <div className='d-flex flex-row gap-2'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "orange" }} width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                                        <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                                                                    </svg>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "red" }} width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                                                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                                                    </svg>
                                                                </div>
                                                            </td>
                                                            : ""}
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>

                        </div>

                    </div>
                </div>
                <nav class="app-pagination">
                    <ul class="pagination justify-content-center">
                        <li class={`page-item ${currentPage !== 1 ? "" : "disabled"}  `}>
                            <button type='button' class="page-link" tabindex="-1" aria-disabled={currentPage !== 1 ? "false" : "true"} onClick={() => handlePagination(0)}>Previous</button>
                        </li>

                        <li class={`page-item `}>
                            <button type='button' class={`page-link ${currentPage + 1 !== activeNumber ? 'active text-white' : ''} `} tabindex="-1" onClick={() => handlePagination(1)}>{currentPage}</button>
                        </li>
                        <li class={`page-item `}>
                            <button type='button' class={`page-link  ${currentPage + 1 === activeNumber ? 'active text-white' : ''}`} tabindex="-1" onClick={() => handlePagination(2)}>{currentPage + 1}</button>
                        </li>

                        <li class="page-item" onClick={() => handlePagination(3)}>
                            <button type='button' class="page-link" >Next</button>
                        </li>
                    </ul>
                </nav>

            </div>
            {/* <div style={{ opacity: 0 }}>
                {
                    showPrintCart === 1 ? <StudentCard studentDataprops={studentData} showPrintCartfun={setShowPrintCard} setLoader={setLoader} /> : ""
                }
            </div>
            <div style={{ opacity: 0 }}>
                {
                    studentPdf === 1 ? <GetReport studentId={studentData.student_id} setStudentPdf={setStudentPdf} setLoader={setLoader} setCurrentRow={setCurrentRow} /> : ""
                }
            </div> */}
            {/* <Loader val={loader} /> */}




            {/* Modal */}
            <div class="modal fade" id="addFileModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Add Student File</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='d-flex flex-column gap-3'>
                                <div className='d-flex flex-row gap-4'>
                                    <div className="email mb-3">
                                        <label className="sr-only" for="signin-email">Student Id</label>
                                        <input id="studentId" name="studentId" type="text" className="form-control signin-email_" placeholder="Student Id" value={studentFileData.studentId} disabled />
                                    </div>
                                    <div className="email mb-3">
                                        <label className="sr-only" for="signin-email">Choose Type</label>
                                        <div className='d-flex flex-column flex-md-row gap-3'>
                                            <input id="regular" name="IsRegular" type="radio" className="form-radio " required="required" checked={studentFileData.IsRegular === true ? true : false} onChange={(e) => { setStudentFileData((value) => ({ ...value, IsRegular: true })) }} /> Regular
                                            <input id="followUp" name="IsRegular" type="radio" className="form-radio" required="required" checked={studentFileData.IsRegular !== true ? true : false} onChange={(e) => { setStudentFileData((value) => ({ ...value, IsRegular: false })) }} /> FollowUp
                                        </div>

                                    </div>
                                </div>
                                <div className="email mb-3">
                                    <label className="sr-only" for="signin-email">Select File</label>
                                    <input id="studentFile" name="formFile" type="file" className="form-control signin-email_" required="required" onChange={(e) => { setStudentFileData((value) => ({ ...value, formFile: e.target.files[0] })) }} />
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                            <button type="button" class="btn app-btn-primary w-100 theme-btn mx-auto" data-bs-dismiss="modal" onClick={handleSaveFile}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
            {
                toaster.show === 1 ?
                    <Toaster show={toaster.show} type={toaster.type} message={toaster.message} close={setToaster} /> : ""
            }
        </>
    )
}
