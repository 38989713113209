import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

export default function BarGraph({ dashboardData }) {

    let [countData, setCountData] = useState([])
    const chartRef = useRef(null);

    let getData = () => {
        let data = dashboardData

        // let data = window.sessionStorage.getItem("userData") ? JSON.parse(window.sessionStorage.getItem("userData")).dashboardData[0] : []
        setCountData(data)
    }
   
    const options = {
        chart: {
            type: 'pie',
            margin: [0, 0, 0, 0],
            spacingTop: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            spacingRight: 0,
            height: '150px',
            // width:"800px"
        },
        title: {
            text: '',
        },
        credits: {
            enabled: false // Disable the Highcharts watermark
        },
        plotOptions: {
            pie: {
                // innerSize: '70%',
                size: "100%",
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return '<b>' + this.point.name + '</b>: <br/>' + Math.round(this.y) + '%';
                    }, // Format to display percentages with one decimal place

                    distance: -40, // Move the labels inside the pie slice
                    style: {
                        textOutline: 'none' // Disable text outline for better readability
                    },
                    showInLegend: true // Show data labels in legend
                },

            },
            borderRadius: 600,
        },
        tooltip: {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.1f}%</b><br/>'
        },
        series: [
            {
                name: 'Student',
                data: [
                    { name: 'Male', y: (countData.maleSevereCount / countData.totalStudent) * 100, color: '#5757bd' },
                    { name: 'Female', y: (countData.femaleSevereCount / countData.totalStudent) * 100, color: '#ef3cd0' },
                ],
            },
        ],

    };

    const options2 = {
        chart: {
            type: 'pie',
            margin: [0, 0, 0, 0],
            spacingTop: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            spacingRight: 0,
            height: '150px'
        },
        title: {
            text: '',
        },
        credits: {
            enabled: false // Disable the Highcharts watermark
        },
        plotOptions: {
            pie: {
                // innerSize: '70%',
                size: "100%",
                dataLabels: {
                    enabled: false,
                },
            },
            borderRadius: 600,
        },
        series: [
            {
                name: 'Student',
                data: [
                    // { name: 'Total Student', y: countData.totalStudent, color: '#178a56' },
                    { name: 'Male', y: (countData.maleSevereCount / countData.totalStudent) * 100, color: '#5757bd' },
                    { name: 'Female', y: (countData.femaleSevereCount / countData.totalStudent) * 100, color: '#ef3cd0' },
                    // { name: 'Yellow', y: 100, color: '#FFCE56' },
                    // { name: 'whute', y: 100, color: '#fff' },
                ],
            },
        ],

    };
    useEffect(() => {
        getData()

        if (chartRef.current) {
            // const chart = chartRef.current.chart;

            // // Calculate the exact center of the plot area
            // const centerX = (chart.plotWidth / 2.7) + chart.plotLeft;
            // const centerY = (chart.plotHeight / 2.4) + chart.plotTop;
            // const center1X = (chart.plotWidth / 2.7) + chart.plotLeft;
            // const center1Y = (chart.plotHeight / 2) + chart.plotTop;

            // // Add a custom label in the center
            // chart.renderer
            //     .label('Students', centerX, centerY)
            //     .attr({
            //         zIndex: 99,
            //     })
            //     .css({
            //         color: '#000',
            //         fontSize: '18px',
            //         fontWeight: "500"
            //     })
            //     .add();

            // chart.renderer
            //     .label('₹ 1500000', center1X, center1Y)
            //     .attr({
            //         zIndex: 99,
            //     })
            //     .css({
            //         color: '#000',
            //         fontSize: '14px',
            //         fontWeight: "600"
            //     })
            //     .add();
        }
    }, [dashboardData])
    return (
        <>
            <div className="row g-4 mb-4  h-100">
                <div className="col-12 col-lg-12">
                    <div class="app-card app-card-chart h-100 shadow-sm">
                        <div class="app-card-header p-3">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-auto">
                                    <h4 class="app-card-title">Severe Students</h4>
                                </div>
                                {/* <div class="col-auto">
                                    <div class="card-header-action">
                                        <a href="charts.html">More charts</a>
                                    </div>
                                </div> */}
                                {/* <div class="col-auto">
                                    <select class="form-select form-select-sm ms-auto d-inline-flex w-auto">
                                        <option value="1" selected>This week</option>
                                        <option value="2">Today</option>
                                        <option value="3">This Month</option>
                                        <option value="3">This Year</option>
                                    </select>
                                </div> */}
                            </div>
                        </div>
                        <div class="app-card-body_ p-3_ p-lg-4_ d-flex flex-row justify-content-center align-items-center h-100">

                            <div class="chart-container pb-5 mb-5" style={{ width: "240px" }}>
                                <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />

                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-12 col-lg-6">
                    <div class="app-card app-card-chart h-100 shadow-sm">
                        <div class="app-card-header p-3">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-auto">
                                    <h4 class="app-card-title">Severe Students</h4>
                                </div>
                              
                                <div class="col-auto">
                                    <select class="form-select form-select-sm ms-auto d-inline-flex w-auto">
                                        <option value="1" selected>This week</option>
                                        <option value="2">Today</option>
                                        <option value="3">This Month</option>
                                        <option value="3">This Year</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="app-card-body p-3 p-lg-4">

                            <div class="chart-container">
                                <HighchartsReact highcharts={Highcharts} options={options2} ref={chartRef} />

                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    )
}
