import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Toaster from '../../components/Toaster'
import GetAssignSchoolsToAdmin from '../../api/Assign/GetAssignSchoolsToAdmin'
import DeleteAssignSchoolsToAdmin from '../../api/Assign/DeleteAssignSchoolsToAdmin'
import toCamelCase from '../../assests/js/CamleCase'

export default function AssignSchoolAdminList({ datachange, dataChangeFun }) {
    let [schooladminList, setschooladminList] = useState([])
    let [schooladminTempList, setschooladminTempList] = useState([])
    let [toaster, setToaster] = useState({
        type: "",
        message: "",
        show: 0
    })
    let getData = async () => {
        let responseSchool = await GetAssignSchoolsToAdmin()
        if (responseSchool.status === 1) {
            setschooladminList(responseSchool.responseValue)
            setschooladminTempList(responseSchool.responseValue)
        }

    }
    let handleDelete = async (id) => {
        let response = await DeleteAssignSchoolsToAdmin(id)
        if (response.status === 1) {
            setToaster({
                type: "Success",
                message: 'Data Deleted!!',
                show: 1
            })
            getData()
        }
        else {
            setToaster({
                type: "alert",
                message: response.responseValue,
                show: 1
            })
        }
    }
    let handleSearch = (e) => {
        let valuesearch = e.target.value.trim().toLowerCase();
        if (valuesearch.length > 0) {
            let data = schooladminList.filter(obj => {
                return Object.values(obj).some(value => {
                    return value != null && value.toString().toLowerCase().includes(valuesearch);
                });
            });
            if (data.length !== 0) {
                setschooladminTempList(data.filter(value => value !== undefined))
            }
            else {
                setschooladminTempList([])
            }
        }
        else {
            setschooladminTempList(schooladminList)

        }

    }
    useEffect(()=>{
        getData();

    }, [])
    useEffect(() => {
        if (datachange === 1) {
            getData();
            dataChangeFun(0)
        }
    }, [datachange])
  return (
    <>
         <div class="row g-3 mb-4 align-items-center justify-content-between">
                <div class="col-auto">
                    <h1 class="app-page-title mb-0">Assign School To Admin List</h1>
                </div>

            </div>
            {/* <div class="row g-3 mb-4 align-items-center justify-content-between">
                <div class="col-auto">
                    <div class="page-utilities">
                        <div class="d-flex flex-column flex-md-row justify-content-between gap-5">
                            <div className='row g-2 justify-content-start justify-content-md-end align-items-center'>
                                <div class="col-auto">

                                    <select class="form-select w-auto">
                                        <option selected value="option-1">Select Session</option>
                                        <option value="option-2">This week</option>
                                        <option value="option-3">This month</option>
                                        <option value="option-4">Last 3 months</option>

                                    </select>
                                </div>

                                <div class="col-auto">

                                    <select class="form-select w-auto">
                                        <option selected value="option-1">Select School</option>
                                        <option value="option-2">This week</option>
                                        <option value="option-3">This month</option>
                                        <option value="option-4">Last 3 months</option>

                                    </select>
                                </div>

                                <div class="col-auto">

                                    <select class="form-select w-auto">
                                        <option selected value="option-1">Select Class</option>
                                        <option value="option-2">This week</option>
                                        <option value="option-3">This month</option>
                                        <option value="option-4">Last 3 months</option>

                                    </select>
                                </div>
                                <div class="col-auto">
                                    <button type="button" class="btn app-btn-secondary">Filter</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className=''>
                <div class="app-card app-card-orders-table shadow-sm mb-5">
                    <div class="app-card-body p-3">
                        <div class="table-responsive">
                            <div class="d-flex flex-row justify-content-end pb-2 me-2">
                                <div class="table-search-form row gx-1 align-items-center">
                                    <div class="col-auto">
                                        <input type="text" id="search-orders" name="searchorders"
                                            class="form-control search-orders" placeholder="Search"  onChange={handleSearch}/>
                                    </div>
                                    {/* <div class="col-auto">
                                    <button type="button" class="btn app-btn-secondary">Search</button>
                                </div> */}
                                </div>

                            </div>
                            <table class="table app-table-hover mb-0 text-left">
                                <thead>
                                    <tr>
                                        {/* <th class="cell">#</th> */}
                                        <th class="cell">Admin</th>
                                        <th class="cell">School</th>
                                        {/* <th class="cell">Mobile No.</th>
                                        <th class="cell">Address</th>
                                        <th class="cell">Pin Code</th>
                                        <th class="cell">City</th>
                                        <th class="cell">State</th> */}
                                        <th class="cell">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        schooladminTempList && schooladminTempList.map((val, ind) => {
                                            return (
                                                <tr>
                                                    <td class="cell">{toCamelCase(val.adminName)}</td>
                                                    <td class="cell"><span class="truncate">{toCamelCase(val.schoolName)}</span></td>
                                                    {/* <td class="cell">Lorem ipsum dolor sit </td>
                                                    <td class="cell">UP
                                                    </td>
                                                    <td class="cell">LKO</td>
                                                    <td class="cell">226003</td> */}
                                                    <td class="cell">
                                                        <div className='d-flex flex-row gap-2'>
                                                            {/* <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "orange" }} width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                                                            </svg> */}
                                                            <svg xmlns="http://www.w3.org/2000/svg" style={{ color: "red" }} width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16" onClick={()=>handleDelete(val.id)}>
                                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                                            </svg>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }



                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                <nav class="app-pagination">
                    <ul class="pagination justify-content-center">
                        <li class="page-item disabled">
                            <Link class="page-link" to="#" tabindex="-1" aria-disabled="true">Previous</Link>
                        </li>
                        <li class="page-item active"><Link class="page-link" to="#">1</Link></li>
                        <li class="page-item"><Link class="page-link" to="#">2</Link></li>
                        <li class="page-item"><Link class="page-link" to="#">3</Link></li>
                        <li class="page-item">
                            <Link class="page-link" to="#">Next</Link>
                        </li>
                    </ul>
                </nav>

            </div>
            {
                toaster.show === 1 ?
                    <Toaster show={toaster.show} type={toaster.type} message={toaster.message} close={setToaster} /> : ""
            }
    </>
  )
}
