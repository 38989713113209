import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <>
            <footer class="app-footer">
                <div class="container text-center py-3">
                    {/* <small class="copyright">Develop  by <Link class="app-link" to="" target="_blank">Moinuddin</Link></small> */}
                </div>
            </footer>
        </>
    )
}
