import React, { useEffect, useState } from 'react'
import Loader from '../../components/Loader'
import Toaster from '../../components/Toaster'
import GetTestimonial from '../../api/Testimonial/GetTestimonial'

export default function VideoTestimonial() {
  let [testimonialData, setTestimonialData] = useState([])
  let [loader, setLoader] = useState(0)
  let [toaster, setToaster] = useState({
    type: "",
    message: "",
    show: 0
  })
  let getData = async () => {
    setLoader(1)
    let response = await GetTestimonial(2)
    if (response.status === 1) {

      setTestimonialData(response.responseValue)
      setLoader(0)
    }
    else {
      setLoader(0)
      setToaster(
        {
          type: "alert",
          message: response.message,
          show: 1
        }
      )

    }
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <>
     <div class="row g-3 mb-4 align-items-center justify-content-between">
                <div class="col-auto">
                    <h1 class="app-page-title mb-0">Videos Testimonial</h1>
                </div>
            </div>
      <div className="testimonial-container">
        {
          testimonialData && testimonialData.map((val) => {
            return (
              <video controls className="img-fluid img-thumbnail" style={{ height: "300px", width: "400px" }} >
                <source src={val.fileUrl} type="video/mp4" />
              </video>
            )
          })
        }


      </div>



      <Loader val={loader} />
      {
        toaster.show === 1 ?
          <Toaster show={toaster.show} type={toaster.type} message={toaster.message} close={setToaster} /> : ""
      }
    </>
  )
}
