function ToDataURL(src) {
    return new Promise((resolve, reject) => {
        var image = new Image();
        image.crossOrigin = 'Anonymous';
        image.onload = function () {
            try {
                // Create a canvas with the same dimensions as the original image
                var canvas = document.createElement('canvas');
                var context = canvas.getContext('2d');
                canvas.width = image.naturalWidth;
                canvas.height = image.naturalHeight;

                // Draw the original image onto the canvas
                context.drawImage(image, 0, 0);

                // Convert the canvas to a data URL in PNG format
                var dataURL = canvas.toDataURL('image/png');

                // Resolve the Promise with the data URL
                resolve(dataURL);
            } catch (error) {
                reject(error);
            }
        };
        image.onerror = function () {
            reject(new Error('Failed to load image'));
        };
        image.src = src;
    });
}

export default ToDataURL;
