import React, { useEffect, useRef, useState } from 'react'
import TopCard from './TopCard'
import BarGraph from './BarGraph'
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import GetSchoolListByAdmin from '../../api/User/GetSchoolListByAdmin';
import GetDashboardData from '../../api/Dasboard/GetDashboardData';
import toCamelCase from '../../assests/js/CamleCase';
// import { Bar } from 'react-chartjs-2';
// import {
//     Chart as ChartJS,
//     CategoryScale,
//     LinearScale,
//     BarElement,
//     Title,
//     Tooltip,
//     Legend,
// } from 'chart.js';
// ChartJS.register(
//     CategoryScale,
//     LinearScale,
//     BarElement,
//     Title,
//     Tooltip,
//     Legend
// );

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, Tooltip, ResponsiveContainer } from 'recharts';

export default function Dashboard() {
    const chartRef = useRef(null);
    let [dashboardData, setDashboardData] = useState([])
    let [schoolList, setSchoolList] = useState([])
    let [responseData, setResponseData] = useState([])
    let getData = async () => {
        let responsiveSchool = await GetSchoolListByAdmin()
        // let responseClass = await GetClassList()
        if (responsiveSchool.status === 1) {
            setSchoolList(responsiveSchool.responseValue)
        }
        // if (responseClass.status === 1) {
        //     setClassList(responseClass.responseValue)
        // }
    }
    let getDashboardData = async (id) => {
        let response = await GetDashboardData(id)
        if (response.status === 1) {
            setDashboardData(response.responseValue[0])
        }
        else {
            setDashboardData({})
        }
    }
    let handleChnage = (e) => {
        let name = e.target.name
        let value = e.target.value
        getDashboardData(value)

    }




    const data = [
        { name: 'PHYSICAL', x: Math.round((dashboardData.malePhysicalAssessmentCount / dashboardData.totalStudent) * 100), y: Math.round((dashboardData.femalePhysicalAssessmentCount / dashboardData.totalStudent) * 100), },
        { name: 'EYE & VISION ', x: Math.round((dashboardData.maleVisionAssessmentCount / dashboardData.totalStudent) * 100), y: Math.round((dashboardData.femaleVisionAssessmentCount / dashboardData.totalStudent) * 100), },
        { name: 'DENTAL ', x: Math.round((dashboardData.maleDentalAssessmentCount / dashboardData.totalStudent) * 100), y: Math.round((dashboardData.femaleDentalAssessmentCount / dashboardData.totalStudent) * 100), },
        { name: 'NUTRITIONAL ', x: Math.round((dashboardData.maleNutriAssessmentCount / dashboardData.totalStudent) * 100), y: Math.round((dashboardData.femaleNutriAssessmentCount / dashboardData.totalStudent) * 100), },
        { name: 'AUDIO & SPEECH ', x: Math.round((dashboardData.maleSpeechAssessmentCount / dashboardData.totalStudent) * 100), y: Math.round((dashboardData.femaleSpeechAssessmentCount / dashboardData.totalStudent) * 100), },
        { name: 'PSYCHOLOGY ', x: Math.round((dashboardData.malePsychologyAssessmentCount / dashboardData.totalStudent) * 100), y: Math.round((dashboardData.femalePsychologyAssessmentCount / dashboardData.totalStudent) * 100), },
        { name: 'GENERAL ', x: Math.round((dashboardData.maleGeneralAssessmentCount / dashboardData.totalStudent) * 100), y: Math.round((dashboardData.femaleGeneralAssessmentCount / dashboardData.totalStudent) * 100), },

    ];
    useEffect(() => {
        getData()
        let data = window.sessionStorage.getItem("userData") ? JSON.parse(window.sessionStorage.getItem("userData")).dashboardData[0] : []
        let datas = window.sessionStorage.getItem("userData") ? JSON.parse(window.sessionStorage.getItem("userData")).responseValue : []
        setResponseData(datas)
        setDashboardData(data)
    }, [])

    return (
        <>
            <div className='d-flex  mb-2'>
                <div className="col-12 col-lg-12">
                    <div class="app-card app-card-chart h-100 shadow-sm" style={{ background: "none" }}>
                        <div class="app-card-header p-2">
                            <div className='d-flex flex-column flex-md-row justify-content-between align-items-center'>

                                <h4 class="app-page-title">Overview</h4>
                                <select class="form-select form-select-sm ms-auto d-inline-flex w-auto" onChange={handleChnage}>
                                    <option selected value="-1">Select School</option>
                                    {
                                        schoolList && schoolList.map((val, ind) => {
                                            return (
                                                <option value={val.id}>{toCamelCase(val.name)}</option>

                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-6 app-card overflow-auto' style={{ maxHeight: "420px" }}>
                    <div className='p-2' dangerouslySetInnerHTML={{ __html: responseData.content }} />
                    {/* <h4>A Great Initiative by Lucknow Smart City Limited</h4>
                    <p>Lucknow Smart City Limited in collaboration with the School Health Program project aims to revolutionize healthcare accessibility for all Municipality board school students in Lucknow. They concern to provide Digital Health Report Card & Health Insurance to every student of Municipality board school students.</p>

                    <p>Through this initiative, every student will have access to a digital health report card and comprehensive health insurance. This initiative is not merely about treating diseases but about proactive health management.</p>

                    <ol>
                        <li>
                            <h5>Early Detection for Potentially Symptomless Diseases</h5>
                            <p>One of the most remarkable aspects of this project is its focus on early detection of diseases that may not present any symptoms. By leveraging advanced technology and proactive healthcare measures, we aim to identify potential health concerns at their nascent stage, ensuring timely intervention and treatment.</p>
                        </li>
                        <li>
                            <h5>Empowering the Future Generation</h5>
                            <p>By providing digital health report cards and health insurance, we are not just ensuring the physical well-being of our students; we are also empowering them with knowledge and resources to lead healthier lives. This initiative underscores our commitment to nurturing a generation that is not just academically strong but also physically and mentally resilient.</p>
                        </li>
                        <li>
                            <h5>Gratitude and Support</h5>
                            <p>We extend our heartfelt gratitude to all stakeholders involved in making this initiative a reality. Together, we are shaping a brighter, healthier future for the students of Lucknow. Let us continue to support and champion initiatives that prioritize the well-being of our future leaders.</p>
                        </li>
                    </ol> */}
                </div>
                <div className='col-3'>
                    <TopCard dashboardData={dashboardData} />
                </div>
                <div className='col-3'>
                    <BarGraph dashboardData={dashboardData} />
                </div>

            </div>
            <div className='row mt-3'>
                <div className="col-12 col-lg-12">
                    <div class="app-card app-card-chart h-100 shadow-sm">
                        <div class="app-card-header p-3">
                            <div class="row_ justify-content-between align-items-center">
                                <div class="col-auto_ d-flex flex-column flex-md-row justify-content-between">
                                    <h4 class="app-card-title">ASSESSMENT WISE IDENTIFICATION</h4>
                                    <select class="form-select form-select-sm ms-auto d-inline-flex w-auto" onChange={handleChnage}>
                                        <option selected value="-1">Select School</option>
                                        {
                                            schoolList && schoolList.map((val, ind) => {
                                                return (
                                                    <option value={val.id}>{toCamelCase(val.name)}</option>

                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                {/* <div class="col-auto">
                                    <div class="card-header-action">
                                        <a href="charts.html">More charts</a>
                                    </div>
                                </div> */}
                                {/* <div class="col-auto">
                                    <select class="form-select form-select-sm ms-auto d-inline-flex w-auto">
                                        <option value="1" selected>This week</option>
                                        <option value="2">Today</option>
                                        <option value="3">This Month</option>
                                        <option value="3">This Year</option>
                                    </select>
                                </div> */}
                            </div>
                        </div>
                        <div class="app-card-body p-3 p-lg-4">

                            <div class="chart-container" >
                                {/* <HighchartsReact highcharts={Highcharts} options={options} /> */}
                                {/* <Bar data={data} options={option} /> */}
                                {/* <ResponsiveContainer width="100%" aspect={2}>
                                <BarChart  data={data} barCategoryGap="5%" barGap="2%">
                                    <CartesianGrid />
                                    <XAxis dataKey="name" angle={-15} textAnchor="end"  tick={{ fontSize: '14px', fill: 'black' }} interval={0} />
                                    <YAxis label={{ value: 'Percentage', angle: -90, position: 'insideLeft', dx: 0 }}/>
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="x" stackId="a" fill="#5757bd" name="Male" barSize={30}/>
                                    <Bar dataKey="y" stackId="a" fill="#ef3cd0" name="Female" barSize={30}/>
                                </BarChart>
                               </ResponsiveContainer> */}
                                <ResponsiveContainer width={"100%"} aspect={2} >
                                    <BarChart data={data} barCategoryGap="5%" barGap="15%">
                                        <CartesianGrid horizontal={false} vertical={false} />
                                        <XAxis dataKey="name" angle={""} textAnchor="middle"  tick={{ fontSize: '14px', fill: 'black' }} interval={0}  />
                                        <YAxis label={{ value: 'Percentage', angle: -90, position: 'insideLeft', dx: 0 }} />
                                        <Tooltip formatter={(value, name, props) => [`${value}%`, `${name}`]} />
                                        <Legend  verticalAlign="top"/>
                                       
                                        <Bar dataKey="x" stackId="a"  fill="#5757bd" name="Male" barSize={30} />
                                        <Bar dataKey="y" stackId="a"  fill="#ef3cd0" name="Female" barSize={30} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
