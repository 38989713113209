import React, { useState } from 'react'
import { json } from 'react-router-dom';
import * as XLSX from 'xlsx';

export default function ImportFile() {

    let [fileData, setFileData] = useState(null)

    let handleExcelFile = () => {
        if (!fileData) {
            console.log("File Not Found")
            return
        }; // If no file selected, return

        const reader = new FileReader();

        reader.onload = function (e) {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            // Choose the first sheet (assuming it's the one you want to read)
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];

            // Convert the worksheet to JSON format
            const jsonData = XLSX.utils.sheet_to_json(worksheet);

            // Process the data as needed
            window.sessionStorage.setItem("StudentData", JSON.stringify(jsonData))
            // console.log(jsonData);
        };

        reader.readAsArrayBuffer(fileData);
    }
    return (
        <>
            <div class="row g-3 mb-4 align-items-center justify-content-between">
                <div class="col-auto">
                    <h1 class="app-page-title mb-0">Import Student Excel File</h1>
                </div>
            </div>
            <div class="app-card app-card-orders-table shadow-sm mb-5">
                <div class="app-card-body p-3">
                    <h6 class="app-page-title_ mb-0">Select Excel File</h6>
                    <div className='mt-3'>
                        <input type="file" className="file-type" onChange={(e) => { setFileData(e.target.files[0]) }} />

                        <div class="col-auto mt-3">
                            <button type='button' class="btn app-btn-secondary" onClick={handleExcelFile}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-upload" viewBox="0 0 16 16">
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                    <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                                </svg> &nbsp;
                                Upload File
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
