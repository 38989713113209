import React, { useState } from 'react'
import AddUser from '../../api/User/AddUser'
import AdminList from './AdminList'
import Toaster from '../../components/Toaster'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
export default function AddAdmin() {

    let [sendForm, setSendForm] = useState({
        "name": "",
        "email": "",
        "mobileNo": "",
        "password": "",
        "pincode": "",
        "cityId": "-1",
        "stateId": "-1",
        "roleId": 0,
        "address": "",
        "formFile": "",
        "Content": "",
        "userId": window.userId,
    })
    let [dataget, setDataGet] = useState(0)
    let [content, setContent] = useState("")
    let [toaster, setToaster] = useState({
        type: "",
        message: "",
        show: 0
    })

    let handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (name !== "formFile") {
            setSendForm((sendForm) => ({ ...sendForm, [name]: value }))
        }
        else {
            setSendForm((sendForm) => ({ ...sendForm, [name]: e.target.files[0] }))
        }
    }

    let handleChangeEditor = (value) => {
        setContent(value)
    }

    let handleSave = async () => {
        if (sendForm.stateId.toString() !== '-1' && sendForm.cityId.toString() !== '-1' && sendForm.name.trim() !== 0 && sendForm.name.trim().length !== 0 && sendForm.email.trim().length !== 0 && sendForm.mobileNo.trim().length !== 0 && sendForm.password.trim().length !== 0 && sendForm.formFile.length !== 0) {
            sendForm["roleId"] = 2
            sendForm["Content"] = content
            let formData = new FormData()
            Object.keys(sendForm).forEach(key => {
                formData.append(key, sendForm[key]);
            });
            // return
            let response = await AddUser(formData)
            if (response.status === 1) {
                setToaster({
                    type: "Success",
                    message: 'Data Save!!',
                    show: 1
                })
                setDataGet(1)
            }
            else {
                setToaster({
                    type: "alert",
                    message: response.message,
                    show: 1
                })
            }
        }
        else {
            console.log("end")

            setToaster({
                type: "alert",
                message: "Please Fill All Fields !!",
                show: 1
            })
        }

    }
    return (
        <>

            <div class="row g-3 mb-4 align-items-center justify-content-between">
                <div class="col-auto">
                    <h1 class="app-page-title mb-0">Add New Admin</h1>
                </div>
            </div>
            <div class="app-card app-card-orders-table shadow-sm mb-5">
                <div class="app-card-body p-3">
                    <div className='mt-3'>
                        <div className='row'>
                            <div className='col'>
                                <div className="email mb-3">
                                    <label className="sr-only" for="signin-email">Admin Name</label>
                                    <input id="schoolname" name="name" type="text" className="form-control signin-email_" placeholder="Enter Admin Name" required="required" onChange={handleChange} />
                                </div>
                                {/* <div className='d-flex flex-row gap-5'>
                                        <div className="email mb-3">
                                            <label className="sr-only" for="signin-email">State</label>
                                            <select class="form-select w-auto">
                                                <option selected value="option-1">Select State</option>
                                                <option value="option-2">This week</option>
                                                <option value="option-3">This month</option>
                                                <option value="option-4">Last 3 months</option>

                                            </select>
                                        </div>

                                        <div className="email mb-3">
                                            <label className="sr-only" for="signin-email">City</label>
                                            <select class="form-select w-auto">
                                                <option selected value="option-1">Select City</option>
                                                <option value="option-2">This week</option>
                                                <option value="option-3">This month</option>
                                                <option value="option-4">Last 3 months</option>

                                            </select>
                                        </div>
                                    </div> */}
                                <div className="email mb-3">
                                    <label className="sr-only" for="signin-email">Admin Mobile No.</label>
                                    <input id="schoolname" name="mobileNo" type="number" className="form-control signin-email_" placeholder="Enter Admin Mobile No." required="required" onChange={handleChange} />
                                </div>

                            </div>
                            <div className='col'>
                                <div className="email mb-3">
                                    <label className="sr-only" for="signin-email">Admin Email</label>
                                    <input id="schooladdress" name="email" type="email" className="form-control signin-email_" placeholder="Enter Admin Email" required="required" onChange={handleChange} />
                                </div>

                                <div className="email mb-3">
                                    <label className="sr-only" for="signin-email">Password</label>
                                    <input id="pincode" name="password" type="text" className="form-control signin-email_" placeholder="Enter Password" required="required" onChange={handleChange} />
                                </div>

                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>

                                <div className='d-flex flex-row gap-5'>
                                    <div className="email mb-3">
                                        <label className="sr-only" for="signin-email">State</label>
                                        <select class="form-select w-auto" name='stateId' onChange={handleChange}>
                                            <option selected value="-1">Select State</option>
                                            <option value="1">Uttar Pradesh</option>
                                            <option value="2">Delhi</option>
                                            <option value="3">Mumbai</option>

                                        </select>
                                    </div>

                                    <div className="email mb-3">
                                        <label className="sr-only" for="signin-email">City</label>
                                        <select class="form-select w-auto" name='cityId' onChange={handleChange}>
                                            <option selected value="-1">Select City</option>
                                            <option value="1">Lucknow</option>
                                            <option value="2">Kanpur</option>
                                            <option value="3">Aligarh</option>

                                        </select>
                                    </div>
                                </div>


                            </div>
                            <div className='col'>
                                <div className="email mb-3">
                                    <label className="sr-only" for="signin-email">Pin Code</label>
                                    <input id="pincode" name="pincode" type="text" className="form-control signin-email_" placeholder="Enter Pin Code" required="required" onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                <div className="email mb-3">
                                    <label className="sr-only" for="signin-email">Address</label>
                                    <input id="address" name="address" type="text" className="form-control signin-email_" placeholder="Enter Address" required="required" onChange={handleChange} />
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="email mb-3">
                                    <label className="sr-only" for="signin-email">Upload File</label>
                                    <input id="upload" name="formFile" type="file" className="form-control signin-email_" placeholder="Select File" required="required" onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                <div className="email mb-3">
                                    <label className="sr-only" for="signin-email">Content</label>
                                    <ReactQuill
                                        theme="snow" // Specify Quill's theme ('snow' is one of the available themes)
                                        value={content}

                                        onChange={handleChangeEditor}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className=' d-flex justify-content-end'>
                            <div className="text-center w-25 ">
                                <button type="button" className="btn app-btn-primary w-100 theme-btn mx-auto" onClick={handleSave}>Save</button>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <AdminList datachange={dataget} dataChangeFun={setDataGet} />
            {
                toaster.show === 1 ?
                    <Toaster show={toaster.show} type={toaster.type} message={toaster.message} close={setToaster} /> : ""
            }
        </>
    )
}
