import './App.css';
import "./assests/css/custome.css"
import Routing from './Service/Routing';

function App() {

  function toPascalCase(strr) {
    if (strr !== undefined && strr !== null) {
      let str = strr.toLowerCase();
      // Replace multiple spaces and line breaks with a single space
      str = str.replace(/\s+/g, ' ');

      // Replace punctuation marks followed by a word character with the punctuation mark plus a space
      str = str.replace(/([.!?])(\w)/g, '$1 $2');

      // Replace multiple consecutive punctuation marks with a single space
      str = str.replace(/[.!?]{2,}/g, ' ');

      // Split the string into words
      const words = str.trim().split(/\s+/);

      // Capitalize the first letter of each word
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
      }

      // Join the words with spaces
      return words.join(' ');
    } else {
      return '';
    }
  }

  function convertTextToPascalCase(node) {
    if (node.nodeType === Node.TEXT_NODE) {
      node.textContent = toPascalCase(node.textContent);
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      for (let i = 0; i < node.childNodes.length; i++) {
        convertTextToPascalCase(node.childNodes[i]);
      }
    }
  }

  // Call the function with the root element of the document (e.g., document.body)
  convertTextToPascalCase(document.body);

  return (
    <Routing />
  );
}

export default App;
