import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export default function TopCard({ dashboardData }) {
    let [countData, setCountData] = useState([])
    const chartRef = useRef(null);

    let getData = () => {
        let data = dashboardData
        // let data = window.sessionStorage.getItem("userData") ? JSON.parse(window.sessionStorage.getItem("userData")).dashboardData[0] : []
        setCountData(data)
    }
    const options = {
        chart: {
            type: 'pie',
            margin: [0, 0, 0, 0],
            spacingTop: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            spacingRight: 0,
            height: '150px'
        },
        title: {
            text: '',
        },
        credits: {
            enabled: false // Disable the Highcharts watermark
        },
        plotOptions: {
            pie: {
                // innerSize: '70%',
                size: "100%",
                dataLabels: {
                    enabled: false,
                },
            },
            borderRadius: 600,
        },
        series: [
            {
                name: 'Student',
                data: [
                    // { name: 'Total Student', y: countData.totalStudent, color: '#178a56' },
                    { name: 'Male', y: countData.maleCount, color: '#5757bd' },
                    { name: 'Female', y: countData.femaleCount, color: '#ef3cd0' },
                    // { name: 'Yellow', y: 100, color: '#FFCE56' },
                    // { name: 'whute', y: 100, color: '#fff' },
                ],
            },
        ],

    };
    useEffect(() => {
        getData()

        if (chartRef.current) {
            const chart = chartRef.current.chart;

            // Calculate the exact center of the plot area
            const centerX = (chart.plotWidth / 2.7) + chart.plotLeft;
            const centerY = (chart.plotHeight / 2.4) + chart.plotTop;
            const center1X = (chart.plotWidth / 2.7) + chart.plotLeft;
            const center1Y = (chart.plotHeight / 2) + chart.plotTop;

            // Add a custom label in the center
            chart.renderer
                .label('Students', centerX, centerY)
                .attr({
                    zIndex: 99,
                })
                .css({
                    color: '#000',
                    fontSize: '18px',
                    fontWeight: "500"
                })
                .add();

            // chart.renderer
            //     .label('₹ 1500000', center1X, center1Y)
            //     .attr({
            //         zIndex: 99,
            //     })
            //     .css({
            //         color: '#000',
            //         fontSize: '14px',
            //         fontWeight: "600"
            //     })
            //     .add();
        }
    }, [dashboardData])
    return (
        <>
            <div class="row g-4 mb-4">
                <div class="col-12 col-lg-12">
                    <div class="app-card app-card-stat shadow-sm h-100" style={{ backgroundImage: "linear-gradient(to left, #ffffff, #cddef2, #7fc4d8, #27a9a3, #178a56)" }}>
                        <div class="app-card-body p-3 p-lg-4">
                            <h4 class="stats-type mb-1" style={{ color: "#fff", fontSize: "18px" }}>Screened Student</h4>
                            <div class="stats-figure">{countData && countData.totalStudent}</div>
                            <div class="stats-meta text-success">
                                {/* <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-up"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" />
                                </svg> 20% */}
                            </div>
                        </div>
                        <Link class="app-card-link-mask" to="#"></Link>
                    </div>
                </div>

                <div class="col-12 col-lg-12" >
                    <div class="app-card app-card-stat shadow-sm h-100" style={{ "backgroundImage": "linear-gradient(to left, #ffffff, #d7d2ef, #afa7df, #857ece, #5757bd)" }}>
                        <div class="app-card-body p-3 p-lg-4">
                            <h4 class="stats-type mb-1 " style={{ color: "#fff", fontSize: "18px" }}>Male</h4>
                            <div class="stats-figure">{countData !== undefined ? countData.maleCount !== 0 ? countData.maleCount : "NA" : "NA"}</div>
                            <div class="stats-meta text-success">
                                {/* <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-down"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" />
                                </svg> 5% */}
                            </div>
                        </div>
                        {/* <Link class="app-card-link-mask" to="#"></Link> */}
                    </div>
                </div>
                <div class="col-12 col-lg-12">
                    <div class="app-card app-card-stat shadow-sm h-100" style={{ "backgroundImage": "linear-gradient(to left, #ffffff, #ebdaff, #e4b1fe, #e781ed, #ef3cd0)" }}>
                        <div class="app-card-body p-3 p-lg-4">
                            <h4 class="stats-type mb-1" style={{ color: "#fff", fontSize: "18px" }}>Female</h4>
                            <div class="stats-figure">{countData !== undefined ? countData.femaleCount !== 0 ? countData.femaleCount : "NA" : "NA"}</div>
                            {/* <div class="stats-meta">
                                Open</div> */}
                        </div>
                        {/* <Link class="app-card-link-mask" to="#"></Link> */}
                    </div>
                </div>
                {/* <div class="col-6 col-lg-3">
                    <div class="app-card app-card-stat shadow-sm " style={{ height: "150px", "backgroundImage": "radial-gradient(circle, #ffffff, #d8d4ec, #b0abd9, #8784c5, #5b5fb2)" }}>
                        <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
                    </div>
                </div> */}
            </div>
        </>
    )
}
