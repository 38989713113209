import React, { useEffect, useState } from 'react'
import GetAdminList from '../../api/User/GetAdminList'
import GetSchoolList from '../../api/User/GetSchoolList'
import AssignSchoolsToAdmin from '../../api/Assign/AssignSchoolsToAdmin'
import Toaster from '../../components/Toaster'
import AssignSchoolAdminList from './AssignSchoolAdminList'
import toCamelCase from '../../assests/js/CamleCase'

export default function AssignSchoolAdmin() {

    let [adminList, setAdminList] = useState([])
    let [schoolList, setSchoolList] = useState([])
    let [sendForm, setSendForm] = useState(
        {
            "adminId": "-1",
            "schoolId": "-1",
            "userId": window.userId,
        }
    )
    let [dataget, setDataGet] = useState(0)

    let getData = async () => {
        let responseAdmin = await GetAdminList()
        if (responseAdmin.status === 1) {
            setAdminList(responseAdmin.responseValue)
        }
        let responseSchool = await GetSchoolList()
        if (responseSchool.status === 1) {
            setSchoolList(responseSchool.responseValue)
        }
    }
    let [toaster, setToaster] = useState({
        type: "",
        message: "",
        show: 0
    })

    let handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        setSendForm((sendform) => ({ ...sendform, [name]: value }))
    }
    let handleSave = async () => {
        console.log("sendForm", sendForm)
        if (sendForm.adminId.toString() !== "-1" && sendForm.schoolId.toString() !== "-1") {
            let response = await AssignSchoolsToAdmin(sendForm)
            if (response.status === 1) {
                setToaster({
                    type: "success",
                    message: "Data Save!!",
                    show: 1
                })
                setDataGet(1)

            }
            else {
                setToaster({
                    type: "alert",
                    message: response.message,
                    show: 1
                })
            }
        }
        else {
            setToaster({
                type: "alert",
                message: "Please Fill All Fields",
                show: 1
            })
        }
    }
    useEffect(() => {
        getData();
    }, [])
    return (
        <>
            <div class="row g-3 mb-4 align-items-center justify-content-between">
                <div class="col-auto">
                    <h1 class="app-page-title mb-0">Assign Admin & School</h1>
                </div>
            </div>
            <div class="app-card app-card-orders-table shadow-sm mb-5">
                <div class="app-card-body p-3">
                    <div className='mt-3 d-flex flex-column flex-md-row gap-md-5'>
                        <div className="email mb-3">
                            <label className="sr-only" for="signin-email">Admin</label>
                            <select class="form-select w-auto" name='adminId' onChange={handleChange}>
                                <option selected value="-1">Select Admin</option>
                                {
                                    adminList && adminList.map((val, ind) => {
                                        return (

                                            <option value={val.id}>{toCamelCase(val.name)}</option>
                                        )
                                    })
                                }
                                {/* <option value="option-3">This month</option>
                                <option value="option-4">Last 3 months</option> */}

                            </select>
                        </div>
                        <div className="email mb-3">
                            <label className="sr-only" for="signin-email">School</label>
                            <select class="form-select w-auto" name='schoolId' onChange={handleChange}>
                                <option selected value="-1">Select School</option>
                                {
                                    schoolList && schoolList.map((val, ind) => {
                                        return (

                                            <option value={val.id}>{toCamelCase(val.name)}</option>
                                        )
                                    })
                                }

                            </select>
                        </div>

                    </div>
                    <div className=' d-flex justify-content-end'>
                        <div className="text-center w-25 ">
                            <button type="button" className="btn app-btn-primary w-100 theme-btn mx-auto" onClick={handleSave}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
            <AssignSchoolAdminList datachange={dataget} dataChangeFun={setDataGet} />
            {
                toaster.show === 1 ?
                    <Toaster show={toaster.show} type={toaster.type} message={toaster.message} close={setToaster} /> : ""
            }
        </>
    )
}
