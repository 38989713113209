import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

export default function Authentication(props) {
    let [Component, setComponent] = useState(<></>)
    // let Component = props.Component;
    let navigate = useNavigate();
    useEffect(() => {
        let token = window.sessionStorage.getItem("userData") ? JSON.parse(window.sessionStorage.getItem("userData")).token.length !== 0 ? true : false : false
        if (token === false) {
            navigate("/")
        }
        else {
            setComponent(props.Component)
        }
    }, [props])
    return (
        <>
            {Component}
        </>

    )
}
