import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import QRCode from 'react-qr-code';
// import companylogo from "../assests/images/app-logo.png"
import companylogo from "../assests/images/app-logo2.jpg"
import clientlogo from "../assests/images/clientlogo.png"
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import toCamelCase from '../assests/js/CamleCase';
import ToDataURL from '../assests/js/ToBase64';
// import clientLogoTemp from "../assests/images/clientlogo.png"
export default function StudentCard({ studentDataprops, showPrintCartfun, setLoader }) {
    // const location = useLocation();
    const pdfRef = useRef(null);
    // let navigate = useNavigate()
    let [studentData, setStudentData] = useState([])
    const localUrl = window.sessionStorage.getItem("userData") ? JSON.parse(window.sessionStorage.getItem("userData")).responseValue.imagePath.replaceAll("\\", "/") : "";

    let [clientlogo, setClientlogo] = useState( ToDataURL(localUrl, function (dataURL) {
        setClientlogo(dataURL)
    }))
    let getData = () => {
        // const queryParams = new URLSearchParams(location.search);

        const studentId = studentDataprops.student_id;
        const studentName = studentDataprops.first_name + " " + (studentDataprops.last_name !== undefined && studentDataprops.last_name !== null ? studentDataprops.last_name : "")
        const classed = studentDataprops.class;
        const school = studentDataprops.school_name;
        // const school = studentDataprops.school_name;
        setStudentData(
            {
                studentId: studentId,
                studentName: studentName,
                classed: classed,
                school: school
            }
        )
        ToDataURL(localUrl, function (dataURL) {
            setClientlogo(dataURL)

        })
        setTimeout(() => {
            convertToPDF()
            // showPrintCartfun(0)
        }, 200)
    }
    const convertToPDF = () => {
        const input = pdfRef.current;
        try {
            html2canvas(input)
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    const pdf = new jsPDF('p', 'mm', [80, 120]); // Portrait, millimeters, A4 size
                    const imgWidth = 80; // A4 size width in mm
                    // const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    const imgHeight = 120;

                    let position = 0;
                    let pageHeight = pdf.internal.pageSize.height;

                    while (position < imgHeight) {
                        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight - position);
                        position += pageHeight;
                        if (position < imgHeight) {
                            pdf.addPage();
                        }
                    }

                    const pdfBlob = pdf.output('blob');

                    // Create a URL for the Blob
                    const pdfURL = URL.createObjectURL(pdfBlob);

                    // Open the PDF in a new tab
                    window.open(pdfURL, '_blank');
                    setLoader(0)
                    showPrintCartfun(0)
                });
        }
        catch (mes) {
            console.log("messa", mes)
        }
    };

    useEffect(() => {
        try {


            // const localURL = "https://api.stufithealth.app/Upload/Image/1713857617198_clientlogo.png";
            // setClientLogo(localURL)
            ToDataURL(localUrl, function (dataURL) {
                setClientlogo(dataURL)

            })

            const loadClientLogo = async () => {
                try {
                    const dataURL = await ToDataURL(localUrl);
                    setClientlogo(dataURL);
                } catch (error) {
                    console.error("Error loading client logo:", error);
                }
            };
        
            loadClientLogo().then(() => {
                getData();
            });

            // getData()
        }
        catch (mes) {
            console.log("messa", mes)
        }
    }, [])

    return (
        // <div className="card" ref={pdfRef}>
        //     <div className="header">
        //         <h1>DIGITAL HEALTH REPORT CARD</h1>
        //         <div className="logo-container">
        //             {/* <img src="logo.png" alt="Logo" className="logo" /> */}
        //             <img src={companylogo} alt="companylogo" width={80} height={80} />
        //         </div>
        //     </div>
        //     <div className="content row ">
        //         <div className="details col-7">
        //             <table className='table table-borderless'>
        //                 <tbody>
        //                     <tr>
        //                         <td><strong>Student ID:</strong></td><td> {studentData && studentData.studentId}</td>
        //                     </tr>
        //                     <tr>
        //                         <td><strong>Name:</strong></td> <td>{studentData && toCamelCase(studentData.studentName)}</td>
        //                     </tr>
        //                     <tr>
        //                         <td><strong>Class:</strong></td> <td>{studentData && studentData.classed}</td>
        //                     </tr>
        //                     <tr>
        //                         <td><strong>School:</strong> </td><td>{studentData && toCamelCase(studentData.school)}</td>
        //                     </tr>
        //                 </tbody>
        //             </table>
        //         </div>
        //         <div className="qr-code ms-5 col-3" style={{zIndex:5}}>
        //             {
        //                 studentData.length !== 0 && <QRCode value={"https://www.stufithealth.app/studentreport/?studentId=" + studentData.studentId} size={150} style={{ marginTop: "20px", }} />
        //             }
        //         </div>
        //     </div>
        //     <div className="footer">
        //         <div className='d-flex flex-column'>
        //             {/* <img src={companylogo} alt="companylogo" width={100} height={100} /> */}
        //         </div>
        //         <div className='d-flex flex-column'>
        //             {clientlogo && <img src={clientlogo} alt="clientlogo" width={100} height={100} />}
        //         </div>
        //     </div>
        // </div>

        <div className='card-container ' ref={pdfRef}>
            <div className='card-top'>
                <div className='top-blue-div'>
                    <div className='d-flex flex-row justify-content-between align-items-center ps-2 pe-4 pt-3'>

                        <img src={companylogo} alt="companylogo" width={70} height={70} />
                        {clientlogo && <img src={clientlogo} alt="clientlogo" width={70} height={70} />}
                    </div>
                </div>
                <div className='top-orange-div'>
                </div>
                <div className='card-qr-code'>
                    {
                        studentData.length !== 0 && <QRCode value={"https://www.stufithealth.app/pdfreport/?studentId=" + studentData.studentId} size={110} />
                    }
                </div>
            </div>

            <div className='student-data '>
                <div className='d-flex flex-row  gap-2'>
                    <label><strong>Student ID:</strong></label>
                    <label>{studentData && studentData.studentId}</label>
                </div>
                <div className='d-flex flex-row gap-2'>
                    <label><strong>Name:</strong></label>
                    <label>{studentData && toCamelCase(studentData.studentName)}</label>
                </div>
                <div className='d-flex flex-row  gap-2'>
                    <label><strong>Class:</strong></label>
                    <label>{studentData && studentData.classed}</label>
                </div>
                <div className='d-flex flex-row  gap-2'>
                    <label><strong>School:</strong></label>
                    <label>{studentData && toCamelCase(studentData.school)}</label>
                </div>
            </div>

            <div className='card-footer '>
                <div className='card-footer-div2'></div>
                <div className='card-footer-div1'></div>
            </div>
        </div>


    )
}
