import React from 'react'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'

export default function CommonLayout({ page }) {
    return (
        <div className='app'>
            <Sidebar />
            <div className='app-wrapper'>
                <div class="app-content pt-3 p-md-3 p-lg-4">
                    {
                        page && page
                    }
                </div>
                <Footer />
            </div>
        </div>
    )
}
