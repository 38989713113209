import React, { useEffect, useState } from 'react'
import GetTestimonial from '../../api/Testimonial/GetTestimonial'
import Loader from '../../components/Loader'
import Toaster from '../../components/Toaster'
import { Gallery } from 'react-grid-gallery'

export default function ImageTestimonial() {
  let [testimonialData, setTestimonialData] = useState([])
  let [loader, setLoader] = useState(0)
  let [toaster, setToaster] = useState({
    type: "",
    message: "",
    show: 0
  })

  const [maximizedImage, setMaximizedImage] = useState(null);

  const handleImageClick = (index) => {
    setMaximizedImage(index);
    document.body.style.overflow = "hidden";
    document.documentElement.scrollTop = 0;

  };

  const handleMaximizeClose = () => {
    document.body.style.overflow = "auto";
    setMaximizedImage(null);

  };
  let getData = async () => {
    setLoader(1)
    let response = await GetTestimonial(1)
    if (response.status === 1) {

      setTestimonialData(response.responseValue)
      setLoader(0)
    }
    else {
      setLoader(0)
      setToaster(
        {
          type: "alert",
          message: response.message,
          show: 1
        }
      )

    }
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <>
      <div class="row g-3 mb-4 align-items-center justify-content-between">
        <div class="col-auto">
          <h1 class="app-page-title mb-0">Images Testimonial</h1>
        </div>
      </div>
      <div className="testimonial-container">
        {
          testimonialData && testimonialData.map((val) => {
            return (
              <img className="img-fluid img-thumbnail" src={val.fileUrl} alt="" style={{ height: "300px", width: "400px" }} onClick={() => handleImageClick(val.fileUrl)} />
            )
          })
        }
        {maximizedImage !== null && (
          <div className="maximized-image-overlay" onClick={handleMaximizeClose}>
            <img
              className="maximized-image "
              src={maximizedImage}
              alt=""
            />
          </div>
        )}

      </div>



      <Loader val={loader} />
      {
        toaster.show === 1 ?
          <Toaster show={toaster.show} type={toaster.type} message={toaster.message} close={setToaster} /> : ""
      }
    </>
  )
}
