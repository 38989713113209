import React, { useEffect, useState } from 'react'
import "../assests/css/pdfstyle.css"
import clientLogoTemp from "../assests/images/clientlogo.png"
import govermentlogo from "../assests/images/govermentlogo.png"
import { useLocation } from 'react-router-dom'
import GetStudentPdfData from '../api/Student/GetStudentPdfData'
import toCamelCase from '../assests/js/CamleCase'
import ToDataURL from '../assests/js/ToBase64'

export default function GetReport({ studentId, setStudentPdf, setLoader, setCurrentRow }) {
  let location = useLocation()
  let [physicalAssessment, setPhysicalAssessment] = useState([])
  let [visionAssessment, setVisionAssessment] = useState([])
  let [soundAssessment, setSoundAssessment] = useState([])
  let [nutritionalAssessment, setNutritionalAssessment] = useState([])
  let [gernalAssessment, setGernalAssessment] = useState([])
  let [dentalAssessment, setDentalAssessment] = useState([])
  let [studentDatas, setStudentDatas] = useState([])
  let [clientLogo, setClientLogo] = useState(clientLogoTemp)
  let [studentid, setStudentId] = useState("")








  function calculateAge(birthDate) {
    // Split the birth date into year, month, and day
    const [year, month, day] = birthDate.split('-').map(Number);

    // Get the current date
    const currentDate = new Date();

    // Get the year, month, and day from the current date
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Month starts from 0
    const currentDay = currentDate.getDate();

    // Calculate the age
    let age = currentYear - year;

    // Adjust age if the current date is before the birth date of this year
    if (currentMonth < month || (currentMonth === month && currentDay < day)) {
      age--;
    }

    return age;
  }





  let getStudentAge = (dob) => {
    let studentDob = new Date(dob)
    let currentDate = new Date()
    let diff = currentDate - studentDob
    let age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
    return age
  }
  function calculateBMI(weightKg, heightCm) {
    // Convert height from centimeters to meters
    const heightM = heightCm / 100;

    // Calculate BMI
    const bmi = weightKg / (heightM * heightM);

    return Math.round(bmi);
  }

  let allRange = (name, value, age) => {
    let all_range = {
      female_height: { "0-1": "50-75", "2-5": "75-100", "6-10": "100-125", "11-15": "125-150", "16-20": "140-157", "21-30": "140-160" },
      male_height: { "0-1": "50-75", "2-5": "75-100", "6-10": "100-125", "11-15": "125-150", "16-20": "150-165", "21-30": "165-173" },
      female_weight: { "0-1": "5-10", "2-5": "10-20", "6-10": "20-30", "11-15": "30-45", "16-20": "45-55", "21-30": "45-60" },
      male_weight: { "0-1": "5-10", "2-5": "10-20", "6-10": "20-30", "11-15": "30-45", "16-20": "45-60", "21-30": "60-70" },
      female_heartrate: { "0-1": "75-100", "2-5": "75-100", "6-10": "75-100", "11-15": "75-100", "16-20": "75-100", "21-30": "75-100" },
      male_heartrate: { "0-1": "75-100", "2-5": "75-100", "6-10": "75-100", "11-15": "75-100", "16-20": "75-100", "21-30": "75-100" },
      female_oxygen: { "0-1": "95-99", "2-5": "95-99", "6-10": "95-99", "11-15": "95-99", "16-20": "95-99", "21-30": "95-99" },
      male_oxygen: { "0-1": "95-99", "2-5": "95-99", "6-10": "95-99", "11-15": "95-99", "16-20": "95-99", "21-30": "95-99" },
      female_temp: { "0-1": "36.6-37.9", "2-5": "36.6-37.9", "6-10": "36.6-37.9", "11-15": "36.6-37.9", "16-20": "36.6-37.9", "21-30": "36.6-37.9" },
      male_temp: { "0-1": "36.6-37.9", "2-5": "36.6-37.9", "6-10": "36.6-37.9", "11-15": "36.6-37.9", "16-20": "36.6-37.9", "21-30": "36.6-37.9" },
      female_bp: {
        "0-1": "65-95",
        "2-5": "80-110",
        "6-10": "90-115",
        "11-15": "100-120",
        "16-20": "110-130",
        "21-30": "110-130",
        "31-40": "115-135",
        "41-50": "120-140",
        "51-60": "125-145",
        "61-70": "130-150",
        "71-100": "135-150"
      },
      male_bp: {
        "0-1": "65-95",
        "2-5": "80-110",
        "6-10": "90-115",
        "11-15": "100-120",
        "16-20": "110-130",
        "21-30": "110-130",
        "31-40": "115-135",
        "41-50": "120-140",
        "51-60": "125-145",
        "61-70": "130-150",
        "71-100": "135-150"
      },


    }
    let found = false;
    let data = []
    let ageData = "0-1"
    let result = null;
    Object.keys(all_range).forEach((AssessmentName, ind) => {
      if (found) return; // Stop the outer loop if already found
      if (name.toString().trim() === AssessmentName.toString().trim()) {
        Object.keys(all_range[AssessmentName]).forEach((keyrange, ind) => {
          if (found) return; // Stop the inner loop if already found
          let range = all_range[AssessmentName][keyrange].split("-");
          if (parseInt(value) >= parseInt(range[0]) && parseInt(value) <= parseInt(range[1])) {
            // let ageData = keyrange.split("-");
            result = ["greenValue", all_range[AssessmentName][keyrange]];
            found = true; // Set the flag to true when data is found
          } else {
            let ageData = keyrange.split("-");
            if (parseInt(age) >= parseInt(ageData[0]) && parseInt(age) <= parseInt(ageData[1])) {
              ageData = all_range[AssessmentName][keyrange];
            }
            result = ["redValue", all_range[AssessmentName][keyrange]];
          }
        });
      }
    });
    return result;
  }

  let getData = async () => {
    let studentDatas = {}
    let regularPhysicalAssessment = {}
    let followUpPhysicalAssessment = {}
    let regularVisionAssessment = {}
    let folowUpVisionAssessment = {}
    let regularSoundAssessment = {}
    let followUpSoundAssessment = {}
    let regularNutritionalAssessment = {}
    let followUpNutritionalAssessment = {}
    let regularGaAssessment = {}
    let followUpGaAssessment = {}
    let regularDentalAssessment = {}
    let followUpDentalAssessment = {}
    let otherAssessment = {}
    const queryParams = new URLSearchParams(location.search);
    let studentIds = queryParams.get('studentId');
    let studentIdr = studentIds !== null ? studentIds : studentId
    setStudentId(studentIdr)
    // let response = await GetStudentPdfData("STUFIT9001IQON")
    let response = await GetStudentPdfData(studentIdr)
    if (response.status === 1) {
      studentDatas = response.responseValue[0].personalDetails !== null ? JSON.parse(response.responseValue[0].personalDetails) : {}
      regularPhysicalAssessment = response.responseValue[0].regularPhysicalAssessment !== null ? JSON.parse(response.responseValue[0].regularPhysicalAssessment) : {}
      followUpPhysicalAssessment = response.responseValue[0].followUpPhysicalAssessment !== null ? JSON.parse(response.responseValue[0].followUpPhysicalAssessment) : {}
      regularVisionAssessment = response.responseValue[0].regularVisionAssessment !== null ? JSON.parse(response.responseValue[0].regularVisionAssessment) : {}
      folowUpVisionAssessment = response.responseValue[0].folowUpVisionAssessment !== null ? JSON.parse(response.responseValue[0].folowUpVisionAssessment) : {}
      regularSoundAssessment = response.responseValue[0].regularSoundAssessment !== null ? JSON.parse(response.responseValue[0].regularSoundAssessment) : {}
      followUpSoundAssessment = response.responseValue[0].followUpSoundAssessment !== null ? JSON.parse(response.responseValue[0].followUpSoundAssessment) : {}
      regularNutritionalAssessment = response.responseValue[0].regularNutritionalAssessment !== null ? JSON.parse(response.responseValue[0].regularNutritionalAssessment) : {}
      followUpNutritionalAssessment = response.responseValue[0].followUpNutritionalAssessment !== null ? JSON.parse(response.responseValue[0].followUpNutritionalAssessment) : {}
      regularGaAssessment = response.responseValue[0].regularGaAssessment !== null ? JSON.parse(response.responseValue[0].regularGaAssessment) : {}
      followUpGaAssessment = response.responseValue[0].followUpGaAssessment !== null ? JSON.parse(response.responseValue[0].followUpGaAssessment) : {}
      regularDentalAssessment = response.responseValue[0].regularDentalAssessment !== null ? JSON.parse(response.responseValue[0].regularDentalAssessment) : {}
      followUpDentalAssessment = response.responseValue[0].folowUpDentalAssessment !== null ? JSON.parse(response.responseValue[0].folowUpDentalAssessment) : {}
      setStudentDatas([
        {
          "name1": "Student Id",
          "value1": studentDatas.student_id,
          "name2": "First Name",
          "value2": (studentDatas.first_name !== undefined ? toCamelCase(studentDatas.first_name) : "-") + "  " + (studentDatas.last_name !== undefined ? toCamelCase(studentDatas.last_name) : "-"),
          "name3": "Aadhar Number",
          "value3": studentDatas.adhar_number,
          "name4": "School Name",
          "value4": toCamelCase(studentDatas.school_name)
        },
        {

          "name1": "Gender",
          "value1": toCamelCase(studentDatas.gender),

          "name2": "Area",
          "value2": toCamelCase(studentDatas.area),

          "name3": "Pin Code",
          "value3": studentDatas.pincode,

          "name4": "Address",
          "value4": (studentDatas.house_no !== undefined ? studentDatas.house_no : "-") + "  " + (studentDatas.street_1 !== undefined ? toCamelCase(studentDatas.street_1) : "-") + "  " + (studentDatas.street_2 !== undefined ? toCamelCase(studentDatas.street_2) : "-") + " " + (studentDatas.state !== undefined ? toCamelCase(studentDatas.state) : "-") + "  " + (studentDatas.city !== undefined ? toCamelCase(studentDatas.city) : "-"),
        },
        {

          "name1": "Email",
          "value1": studentDatas.email,

          "name2": "Contact Number",
          "value2": studentDatas.contact_number,

          "name3": "Date Of Birth",
          "value3": studentDatas.dob,

          "name4": "Father's Name",
          "value4": toCamelCase(studentDatas.fathers_name),
        },
        {

          "name1": "Mother's Name",
          "value1": toCamelCase(studentDatas.mothers_name)
          ,

          "name2": "Class",
          "value2": studentDatas.class
          ,

          "name3": "Section",
          "value3": studentDatas.section
          ,

          "name4": "Siblings",
          "value4": studentDatas.siblings
          ,
        }
        // {
        //   "name": "First Name",
        //   "value": (studentDatas.first_name !== undefined ? toCamelCase(studentDatas.first_name) : "-") + "  " + (studentDatas.last_name !== undefined ? toCamelCase(studentDatas.last_name) : "-")
        // },
        // {
        //     "name": "Last Name",
        //     "value": studentDatas.last_name
        // },
        // {
        //   "name": "Aadhar Number",
        //   "value": studentDatas.adhar_number
        // },
        // {
        //   "name": "School Name",
        //   "value": toCamelCase(studentDatas.school_name)
        // },
        // {
        //   "name": "Gender",
        //   "value": toCamelCase(studentDatas.gender)
        // },
        // {
        //   "name": "Area",
        //   "value": toCamelCase(studentDatas.area)
        // },
        // {
        //   "name": "Pin Code",
        //   "value": studentDatas.pincode
        // },
        // {
        //   "name": "Address",
        //   "value": (studentDatas.house_no !== undefined ? studentDatas.house_no : "-") + "  " + (studentDatas.street_1 !== undefined ? toCamelCase(studentDatas.street_1) : "-") + "  " + (studentDatas.street_2 !== undefined ? toCamelCase(studentDatas.street_2) : "-") + " " + (studentDatas.state !== undefined ? toCamelCase(studentDatas.state) : "-") + "  " + (studentDatas.city !== undefined ? toCamelCase(studentDatas.city) : "-")
        // },
        // {
        //     "name": "Street 1",
        //     "value": studentDatas.street_1
        // },
        // {
        //     "name": "Street 2",
        //     "value": studentDatas.street_2
        // },
        // {
        //     "name": "House No.",
        //     "value": studentDatas.house_no
        // },
        // {
        //   "name": "Email",
        //   "value": studentDatas.email
        // },
        // {
        //   "name": "Contact Number",
        //   "value": studentDatas.contact_number
        // },
        // {
        //   "name": "Date Of Birth",
        //   "value": studentDatas.dob
        // },
        // {
        //   "name": "Father's Name",
        //   "value": toCamelCase(studentDatas.fathers_name)
        // },
        // {
        //   "name": "Mother's Name",
        //   "value": toCamelCase(studentDatas.mothers_name)
        // },
        // {
        //   "name": "Class",
        //   "value": studentDatas.class
        // },
        // {
        //   "name": "Section",
        //   "value": studentDatas.section
        // },
        // {
        //   "name": "Siblings",
        //   "value": studentDatas.siblings
        // },
        // {
        //     "name": "City",
        //     "value": studentDatas.city
        // },
        // {
        //     "name": "State",
        //     "value": studentDatas.state
        // },
      ])

      setPhysicalAssessment([
        {
          "test_name": "Height",
          regularresult: regularPhysicalAssessment && regularPhysicalAssessment.height,
          followupresult: followUpPhysicalAssessment && followUpPhysicalAssessment.height,
          reference: allRange(studentDatas.gender === "male" ? "male_height" : "female_height", regularPhysicalAssessment.height, getStudentAge(studentDatas.dob))[1],
          "unit": " cm",
          "classnameRegular": allRange(studentDatas.gender === "male" ? "male_height" : "female_height", regularPhysicalAssessment.height, getStudentAge(studentDatas.dob))[0],
          "classnameFollowUp": allRange(studentDatas.gender === "male" ? "male_height" : "female_height", followUpPhysicalAssessment.height, getStudentAge(studentDatas.dob))[0]
        },
        {

          "test_name": "Weight",
          regularresult: regularPhysicalAssessment && regularPhysicalAssessment.weight,
          followupresult: followUpPhysicalAssessment && followUpPhysicalAssessment.weight,
          reference: allRange(studentDatas.gender === "male" ? "male_weight" : "female_weight", regularPhysicalAssessment.weight, getStudentAge(studentDatas.dob))[1],
          "unit": " kg",
          "classnameRegular": allRange(studentDatas.gender === "male" ? "male_weight" : "female_weight", regularPhysicalAssessment.weight, getStudentAge(studentDatas.dob))[0],
          "classnameFollowUp": allRange(studentDatas.gender === "male" ? "male_weight" : "female_weight", followUpPhysicalAssessment.weight, getStudentAge(studentDatas.dob))[0]


        },
        {

          "test_name": "BMI",
          regularresult: regularPhysicalAssessment && calculateBMI(regularPhysicalAssessment.weight, regularPhysicalAssessment.height),
          followupresult: followUpPhysicalAssessment && calculateBMI(followUpPhysicalAssessment.weight, followUpPhysicalAssessment.height),
          // reference: allRange(studentDatas.gender === "male" ? "male_height" : "female_height", regularPhysicalAssessment.bmi, getStudentAge(studentDatas.dob))[1],
          "unit": " kg/m^2",
          // "classnameRegular": allRange(studentDatas.gender === "male" ? "male_height" : "female_height", regularPhysicalAssessment.height, getStudentAge(studentDatas.dob))[0],
          // "classnameFollowUp": allRange(studentDatas.gender === "male" ? "male_height" : "female_height", followUpPhysicalAssessment.height, getStudentAge(studentDatas.dob))[0]
          "reference": "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Body Temprature",
          regularresult: regularPhysicalAssessment && regularPhysicalAssessment.body_temprature,
          followupresult: followUpPhysicalAssessment && followUpPhysicalAssessment.body_temprature,
          reference: allRange(studentDatas.gender === "male" ? "male_temp" : "female_temp", regularPhysicalAssessment.body_temprature, getStudentAge(studentDatas.dob))[1],
          "unit": " C",
          "classnameRegular": allRange(studentDatas.gender === "male" ? "male_temp" : "female_temp", regularPhysicalAssessment.body_temprature, getStudentAge(studentDatas.dob))[0],
          "classnameFollowUp": allRange(studentDatas.gender === "male" ? "male_temp" : "female_temp", followUpPhysicalAssessment.body_temprature, getStudentAge(studentDatas.dob))[0]


        },
        {

          "test_name": "BP",
          regularresult: regularPhysicalAssessment && (regularPhysicalAssessment.systolic_blood_pressure_mmhg !== undefined ? regularPhysicalAssessment.systolic_blood_pressure_mmhg : "-") + " / " + (regularPhysicalAssessment.diastolic_blood_pressure_mmhg !== undefined ? regularPhysicalAssessment.diastolic_blood_pressure_mmhg : "-"),
          followupresult: followUpPhysicalAssessment && (followUpPhysicalAssessment.systolic_blood_pressure_mmhg !== undefined ? followUpPhysicalAssessment.systolic_blood_pressure_mmhg : "-") + " / " + (followUpPhysicalAssessment.diastolic_blood_pressure_mmhg !== undefined ? followUpPhysicalAssessment.diastolic_blood_pressure_mmhg : "-"),
          reference: allRange(studentDatas.gender === "male" ? "male_bp" : "female_bp", (regularPhysicalAssessment.systolic_blood_pressure_mmhg !== undefined ? regularPhysicalAssessment.systolic_blood_pressure_mmhg : "-") + " / " + (regularPhysicalAssessment.diastolic_blood_pressure_mmhg !== undefined ? regularPhysicalAssessment.diastolic_blood_pressure_mmhg : "-"), getStudentAge(studentDatas.dob))[1],
          "unit": " mmHg",
          "classnameRegular": allRange(studentDatas.gender === "male" ? "male_bp" : "female_bp", (regularPhysicalAssessment.systolic_blood_pressure_mmhg !== undefined ? regularPhysicalAssessment.systolic_blood_pressure_mmhg : "-") + " / " + (regularPhysicalAssessment.diastolic_blood_pressure_mmhg !== undefined ? regularPhysicalAssessment.diastolic_blood_pressure_mmhg : "-"), getStudentAge(studentDatas.dob))[0],
          "classnameFollowUp": allRange(studentDatas.gender === "male" ? "male_bp" : "female_bp", (followUpPhysicalAssessment.systolic_blood_pressure_mmhg !== undefined ? followUpPhysicalAssessment.systolic_blood_pressure_mmhg : "-") + " / " + (followUpPhysicalAssessment.diastolic_blood_pressure_mmhg !== undefined ? followUpPhysicalAssessment.diastolic_blood_pressure_mmhg : "-"), getStudentAge(studentDatas.dob))[0],

        },
        // {

        //   "test_name": "Blood Group",
        //   regularresult: regularPhysicalAssessment.blood_group,
        //   followupresult: followUpPhysicalAssessment.blood_group,
        //   reference: "",
        //   "unit": "",
        //   "classnameRegular": "",
        //   "classnameFollowUp": ""
        // },
        {

          "test_name": "Pulse Rate",
          regularresult: regularPhysicalAssessment && regularPhysicalAssessment.pulse_rate_bpm,
          followupresult: followUpPhysicalAssessment && followUpPhysicalAssessment.pulse_rate_bpm,
          reference: allRange(studentDatas.gender === "male" ? "male_heartrate" : "female_heartrate", regularPhysicalAssessment.pulse_rate_bpm, getStudentAge(studentDatas.dob))[1],
          "unit": " bpm",
          "classnameRegular": allRange(studentDatas.gender === "male" ? "male_heartrate" : "female_heartrate", regularPhysicalAssessment.pulse_rate_bpm, getStudentAge(studentDatas.dob))[0],
          "classnameFollowUp": allRange(studentDatas.gender === "male" ? "male_heartrate" : "female_heartrate", followUpPhysicalAssessment.pulse_rate_bpm, getStudentAge(studentDatas.dob))[0]

        },
        {

          "test_name": "Oxygen Saturation",
          regularresult: regularPhysicalAssessment !== null ? regularPhysicalAssessment.oxygen_saturation === undefined || regularPhysicalAssessment.oxygen_saturation.length === 0 ? 95 : regularPhysicalAssessment.oxygen_saturation : "",
          followupresult: followUpPhysicalAssessment !== null ? followUpPhysicalAssessment.oxygen_saturation === undefined || followUpPhysicalAssessment.oxygen_saturation.length === 0 ? 95 : followUpPhysicalAssessment.oxygen_saturation : "",
          reference: allRange(studentDatas.gender === "male" ? "male_oxygen" : "female_oxygen", regularPhysicalAssessment.oxygen_saturation, getStudentAge(studentDatas.dob))[1],
          "unit": " %",
          "classnameRegular": allRange(studentDatas.gender === "male" ? "male_oxygen" : "female_oxygen", regularPhysicalAssessment.oxygen_saturation === undefined || regularPhysicalAssessment.oxygen_saturation.length === 0 ? 95 : regularPhysicalAssessment.oxygen_saturation, getStudentAge(studentDatas.dob))[0],
          "classnameFollowUp": allRange(studentDatas.gender === "male" ? "male_oxygen" : "female_oxygen", followUpPhysicalAssessment.oxygen_saturation === undefined || followUpPhysicalAssessment.oxygen_saturation.length === 0 ? 95 : followUpPhysicalAssessment.oxygen_saturation, getStudentAge(studentDatas.dob))[0]

        },
        {

          "test_name": "Posture",
          regularresult: regularPhysicalAssessment && regularPhysicalAssessment.posture,
          followupresult: followUpPhysicalAssessment && followUpPhysicalAssessment.posture,
          reference: "",
          "unit": "",
          "classnameRegular": regularPhysicalAssessment && regularPhysicalAssessment.posture !== undefined ? regularPhysicalAssessment.posture.toLowerCase() === "worst".toLowerCase() ? "redValue" : "" : "",
          "classnameFollowUp": followUpPhysicalAssessment && followUpPhysicalAssessment.posture !== undefined ? followUpPhysicalAssessment.posture.toLowerCase() === "worst".toLowerCase() ? "redValue" : "" : ""

        },

        {

          "test_name": "Upper Limb ROM",
          regularresult: regularPhysicalAssessment && regularPhysicalAssessment && regularPhysicalAssessment.upper_limb_range_of_motion,
          followupresult: followUpPhysicalAssessment && followUpPhysicalAssessment.upper_limb_range_of_motion,
          reference: "",
          "unit": "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Lower Limb ROM",
          regularresult: regularPhysicalAssessment && regularPhysicalAssessment && regularPhysicalAssessment.lower_limb_range_of_motion,
          followupresult: followUpPhysicalAssessment && followUpPhysicalAssessment.lower_limb_range_of_motion,
          reference: "",
          "unit": "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Neck ROM",
          regularresult: regularPhysicalAssessment && regularPhysicalAssessment && regularPhysicalAssessment.neck_rom,
          followupresult: followUpPhysicalAssessment && followUpPhysicalAssessment.neck_rom,
          reference: "",
          "unit": "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Dystrophy",
          regularresult: regularPhysicalAssessment && regularPhysicalAssessment && regularPhysicalAssessment.dystrophy,
          followupresult: followUpPhysicalAssessment && followUpPhysicalAssessment.dystrophy,
          reference: "",
          "unit": "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Atrophy",
          regularresult: regularPhysicalAssessment && regularPhysicalAssessment && regularPhysicalAssessment.atrophy,
          followupresult: followUpPhysicalAssessment && followUpPhysicalAssessment.atrophy,
          reference: "",
          "unit": "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Endurance",
          regularresult: regularPhysicalAssessment && regularPhysicalAssessment && regularPhysicalAssessment.endurance,
          followupresult: followUpPhysicalAssessment && followUpPhysicalAssessment.endurance,
          reference: "",
          "unit": "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Stamina",
          regularresult: regularPhysicalAssessment && regularPhysicalAssessment.stamina,
          followupresult: followUpPhysicalAssessment && followUpPhysicalAssessment.stamina,
          reference: "",
          "unit": "",
          "classnameRegular": regularPhysicalAssessment && regularPhysicalAssessment.stamina !== undefined ? regularPhysicalAssessment.stamina.toLowerCase() === "weak".toLowerCase() ? "redValue" : "" : "",
          "classnameFollowUp": followUpPhysicalAssessment && followUpPhysicalAssessment.stamina !== undefined ? followUpPhysicalAssessment.stamina.toLowerCase() === "weak".toLowerCase() ? "redValue" : "" : ""

        },
        {

          "test_name": "Pain",
          regularresult: regularPhysicalAssessment && regularPhysicalAssessment.pain,
          followupresult: followUpPhysicalAssessment && followUpPhysicalAssessment.pain,
          reference: "",
          "unit": "",
          "classnameRegular": regularPhysicalAssessment && regularPhysicalAssessment.pain !== undefined ? regularPhysicalAssessment.pain.toLowerCase() === "neurological".toLowerCase() ? "redValue" : "" : "",
          "classnameFollowUp": followUpPhysicalAssessment && followUpPhysicalAssessment.pain !== undefined ? followUpPhysicalAssessment.pain.toLowerCase() === "neurological".toLowerCase() ? "redValue" : "" : ""

        },
        {

          "test_name": "Spasm",
          regularresult: regularPhysicalAssessment && regularPhysicalAssessment.spasm,
          followupresult: followUpPhysicalAssessment && followUpPhysicalAssessment.spasm,
          reference: "",
          "unit": "",
          "classnameRegular": regularPhysicalAssessment && regularPhysicalAssessment.spasm !== undefined ? regularPhysicalAssessment.spasm.toLowerCase() === "heavy".toLowerCase() ? "redValue" : "" : "",
          "classnameFollowUp": followUpPhysicalAssessment && followUpPhysicalAssessment.spasm !== undefined ? followUpPhysicalAssessment.spasm.toLowerCase() === "heavy".toLowerCase() ? "redValue" : "" : ""

        },
        {

          "test_name": "Advice",
          regularresult: regularPhysicalAssessment && regularPhysicalAssessment.pa_advice,
          followupresult: followUpPhysicalAssessment && followUpPhysicalAssessment.pa_advice,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Remark",
          regularresult: regularPhysicalAssessment && regularPhysicalAssessment.pa_remark,
          followupresult: followUpPhysicalAssessment && followUpPhysicalAssessment.pa_remark,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },

      ])

      setVisionAssessment([
        {
          "test_name": "Eye Vision",
          regularresult: regularVisionAssessment && (regularVisionAssessment.vision_right_eye !== undefined ? "Lt. " + regularVisionAssessment.vision_right_eye : "-") + " | " + (regularVisionAssessment.vision_left_eye !== undefined ? "Rt. " + regularVisionAssessment.vision_left_eye : "-"),
          followupresult: folowUpVisionAssessment && (folowUpVisionAssessment.vision_right_eye !== undefined ? "Lt. " + folowUpVisionAssessment.vision_right_eye : "-") + " | " + (folowUpVisionAssessment.vision_left_eye !== undefined ? "Rt. " + folowUpVisionAssessment.vision_left_eye : "-"),
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""
        },
        {

          "test_name": "Refraction",
          regularresult: regularVisionAssessment && regularVisionAssessment.refraction,
          followupresult: folowUpVisionAssessment && folowUpVisionAssessment.refraction,
          reference: "",
          "classnameRegular": regularVisionAssessment && regularVisionAssessment.refraction !== undefined ? regularVisionAssessment.refraction.toLowerCase() === "refractive error".toLowerCase() ? "redValue" : "" : "",
          "classnameFollowUp": folowUpVisionAssessment && folowUpVisionAssessment.refraction !== undefined ? folowUpVisionAssessment.refraction.toLowerCase() === "refractive error".toLowerCase() ? "redValue" : "" : ""

        },
        {

          "test_name": "Colour",
          regularresult: regularVisionAssessment && regularVisionAssessment.colour_vision,
          followupresult: folowUpVisionAssessment && folowUpVisionAssessment.colour_vision,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Disease",
          regularresult: regularVisionAssessment && regularVisionAssessment.eye_disease,
          followupresult: folowUpVisionAssessment && folowUpVisionAssessment.eye_disease,
          reference: "",
          "classnameRegular": !regularVisionAssessment && regularVisionAssessment.eye_disease !== undefined ? regularVisionAssessment.eye_disease.toLowerCase() === "dryness".toLowerCase() ? "redValue" : "" : "",
          "classnameFollowUp": !folowUpVisionAssessment && folowUpVisionAssessment.eye_disease !== undefined ? folowUpVisionAssessment.eye_disease.toLowerCase() === "dryness error".toLowerCase() ? "redValue" : "" : ""

        },
        {

          "test_name": "Squint",
          regularresult: regularVisionAssessment && regularVisionAssessment.squint,
          followupresult: folowUpVisionAssessment && folowUpVisionAssessment.squint,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Ptosis",
          regularresult: regularVisionAssessment && regularVisionAssessment.ptosis,
          followupresult: folowUpVisionAssessment && folowUpVisionAssessment.ptosis,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Nystagmus",
          regularresult: regularVisionAssessment && regularVisionAssessment.nystagmus,
          followupresult: folowUpVisionAssessment && folowUpVisionAssessment.nystagmus,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Cataract",
          regularresult: regularVisionAssessment && regularVisionAssessment.cataract,
          followupresult: folowUpVisionAssessment && folowUpVisionAssessment.cataract,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Micro Eye Ball",
          regularresult: regularVisionAssessment && regularVisionAssessment.micro_eye_ball,
          followupresult: folowUpVisionAssessment && folowUpVisionAssessment.micro_eye_ball,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Itching",
          regularresult: regularVisionAssessment && regularVisionAssessment.itching,
          followupresult: folowUpVisionAssessment && folowUpVisionAssessment.itching,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Dryness",
          regularresult: regularVisionAssessment && regularVisionAssessment.dryness,
          followupresult: folowUpVisionAssessment && folowUpVisionAssessment.dryness,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Frequent Tear",
          regularresult: regularVisionAssessment && regularVisionAssessment.frequent_tear,
          followupresult: folowUpVisionAssessment && folowUpVisionAssessment.frequent_tear,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Red Eyes",
          regularresult: regularVisionAssessment && regularVisionAssessment.red_eyes,
          followupresult: folowUpVisionAssessment && folowUpVisionAssessment.red_eyes,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {
          "test_name": "Yellowish Eyes",
          regularresult: regularVisionAssessment && regularVisionAssessment.yellowish_eyes,
          followupresult: folowUpVisionAssessment && folowUpVisionAssessment.yellowish_eyes,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""
        },
        {

          "test_name": "Advice",
          regularresult: regularVisionAssessment && regularVisionAssessment.va_advice,
          followupresult: folowUpVisionAssessment && folowUpVisionAssessment.va_advice,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Remark",
          regularresult: regularVisionAssessment && regularVisionAssessment.va_remark,
          followupresult: folowUpVisionAssessment && folowUpVisionAssessment.va_remark,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },

      ])

      setSoundAssessment([
        {
          "test_name": "Speech Milestone",
          regularresult: regularSoundAssessment && regularSoundAssessment.speech_milestone,
          followupresult: followUpSoundAssessment && followUpSoundAssessment.speech_milestone,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""
        },
        {

          "test_name": "Comprehension",
          regularresult: regularSoundAssessment && regularSoundAssessment.comprehension,
          followupresult: followUpSoundAssessment && followUpSoundAssessment.comprehension,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Expression",
          regularresult: regularSoundAssessment && regularSoundAssessment.expression,
          followupresult: followUpSoundAssessment && followUpSoundAssessment.expression,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Mode Of Communication",
          regularresult: regularSoundAssessment && regularSoundAssessment.mode_of_communication,
          followupresult: followUpSoundAssessment && followUpSoundAssessment.mode_of_communication,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Audiotory Skill",
          regularresult: regularSoundAssessment && regularSoundAssessment.audiotory_skill,
          followupresult: followUpSoundAssessment && followUpSoundAssessment.audiotory_skill,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Hearing Loss",
          regularresult: regularSoundAssessment && regularSoundAssessment.hearing_loss,
          followupresult: followUpSoundAssessment && followUpSoundAssessment.hearing_loss,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Tinnitus",
          regularresult: regularSoundAssessment && regularSoundAssessment.tinnitus,
          followupresult: followUpSoundAssessment && followUpSoundAssessment.tinnitus,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Nystagmus",
          regularresult: regularSoundAssessment && regularSoundAssessment.nystagmus,
          followupresult: followUpSoundAssessment.nystagmus,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Respond On Named Call",
          regularresult: regularSoundAssessment && regularSoundAssessment.respond_on_named_call,
          followupresult: followUpSoundAssessment && followUpSoundAssessment.respond_on_named_call,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Hearing Assessment Result",
          regularresult: regularSoundAssessment && regularSoundAssessment.hearing_Assessment_result,
          followupresult: followUpSoundAssessment && followUpSoundAssessment.hearing_Assessment_result,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Ear Wax",
          regularresult: regularSoundAssessment && regularSoundAssessment.ear_wax,
          followupresult: followUpSoundAssessment && followUpSoundAssessment.ear_wax,
          reference: "",
          "classnameRegular": regularSoundAssessment && regularSoundAssessment.ear_wax !== undefined ? regularSoundAssessment.ear_wax.toLowerCase() === "bilateral".toLowerCase() ? "redValue" : "" : "",
          "classnameFollowUp": followUpSoundAssessment && followUpSoundAssessment.ear_wax !== undefined ? followUpSoundAssessment.ear_wax.toLowerCase() === "bilateral".toLowerCase() ? "redValue" : "" : ""

        },
        {

          "test_name": "Ear Pain",
          regularresult: regularSoundAssessment && regularSoundAssessment.ear_pain,
          followupresult: followUpSoundAssessment && followUpSoundAssessment.ear_pain,
          reference: "",
          "classnameRegular": regularSoundAssessment && regularSoundAssessment.ear_pain !== undefined ? regularSoundAssessment.ear_pain.toLowerCase() === "both".toLowerCase() ? "redValue" : "" : "",
          "classnameFollowUp": followUpSoundAssessment && followUpSoundAssessment.ear_pain !== undefined ? followUpSoundAssessment.ear_pain.toLowerCase() === "both".toLowerCase() ? "redValue" : "" : ""

        },

        {

          "test_name": "Advice",
          regularresult: regularSoundAssessment && regularSoundAssessment.sa_advice,
          followupresult: followUpSoundAssessment.sa_advice,
          reference: ""

        },
        {

          "test_name": "Remark",
          regularresult: regularSoundAssessment && regularSoundAssessment.sa_remark,
          followupresult: followUpSoundAssessment && followUpSoundAssessment.sa_remark,
          reference: ""

        },

      ])

      setNutritionalAssessment([
        {
          "test_name": "Pale or Yellowish Skin",
          regularresult: regularNutritionalAssessment && regularNutritionalAssessment.pale_or_yellowish_skin,
          followupresult: followUpNutritionalAssessment && followUpNutritionalAssessment.pale_or_yellowish_skin,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""
        },
        {

          "test_name": "Irregular Heartbeats",
          regularresult: regularNutritionalAssessment && regularNutritionalAssessment.irregular_heartbeats,
          followupresult: followUpNutritionalAssessment && followUpNutritionalAssessment.irregular_heartbeats,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Shortness Of Breath",
          regularresult: regularNutritionalAssessment && regularNutritionalAssessment.shortness_of_breath,
          followupresult: followUpNutritionalAssessment.shortness_of_breath,
          reference: "",
          "classnameRegular": regularNutritionalAssessment && regularNutritionalAssessment.shortness_of_breath !== undefined ? regularNutritionalAssessment.shortness_of_breath.toLowerCase() === "yes".toLowerCase() ? "redValue" : "" : "",
          "classnameFollowUp": followUpNutritionalAssessment && followUpNutritionalAssessment.shortness_of_breath !== undefined ? followUpNutritionalAssessment.shortness_of_breath.toLowerCase() === "yes".toLowerCase() ? "redValue" : "" : ""

        },
        {

          "test_name": "Dizziness Headness",
          regularresult: regularNutritionalAssessment && regularNutritionalAssessment.dizziness_headness,
          followupresult: followUpNutritionalAssessment && followUpNutritionalAssessment.dizziness_headness,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Audiotory Skill",
          regularresult: regularNutritionalAssessment && regularNutritionalAssessment.audiotory_skill,
          followupresult: followUpNutritionalAssessment && followUpNutritionalAssessment.audiotory_skill,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Cold Hands Feet",
          regularresult: regularNutritionalAssessment && regularNutritionalAssessment.cold_hands_feet,
          followupresult: followUpNutritionalAssessment && followUpNutritionalAssessment.cold_hands_feet,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Anemia",
          regularresult: regularNutritionalAssessment && regularNutritionalAssessment.anemia,
          followupresult: followUpNutritionalAssessment.anemia,
          reference: "",
          "classnameRegular": regularNutritionalAssessment && regularNutritionalAssessment.anemia !== undefined ? regularNutritionalAssessment.anemia.toLowerCase() === "poor".toLowerCase() ? "redValue" : "" : "",
          "classnameFollowUp": followUpNutritionalAssessment && followUpNutritionalAssessment.anemia !== undefined ? followUpNutritionalAssessment.anemia.toLowerCase() === "poor".toLowerCase() ? "redValue" : "" : ""

        },
        {

          "test_name": "Fatigue",
          regularresult: regularNutritionalAssessment && regularNutritionalAssessment.fatigue,
          followupresult: followUpNutritionalAssessment.fatigue,
          reference: "",
          "classnameRegular": regularNutritionalAssessment && regularNutritionalAssessment.oral_hygiene_status !== undefined ? regularNutritionalAssessment.oral_hygiene_status.toLowerCase() === "yes".toLowerCase() ? "redValue" : "" : "",
          "classnameFollowUp": followUpNutritionalAssessment && followUpNutritionalAssessment.oral_hygiene_status !== undefined ? followUpNutritionalAssessment.oral_hygiene_status.toLowerCase() === "yes".toLowerCase() ? "redValue" : "" : ""

        },
        {

          "test_name": "Vitamin D Deficiency",
          regularresult: regularNutritionalAssessment && regularNutritionalAssessment.vitamin_d_deficiency,
          followupresult: followUpNutritionalAssessment && followUpNutritionalAssessment.vitamin_d_deficiency,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Obesity",
          regularresult: regularNutritionalAssessment && regularNutritionalAssessment.obesity,
          followupresult: followUpNutritionalAssessment && followUpNutritionalAssessment.obesity,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Ideal Body Weight",
          regularresult: regularNutritionalAssessment && calculateIdealMetricsFromBMI(studentDatas.gender, regularPhysicalAssessment && regularPhysicalAssessment.height, studentDatas.dob).idealBodyWeight,
          followupresult: followUpNutritionalAssessment && calculateIdealMetricsFromBMI(studentDatas.gender, followUpPhysicalAssessment && followUpPhysicalAssessment.height, studentDatas.dob).idealBodyWeight,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Ideal Calories Estimation",
          regularresult: regularNutritionalAssessment && calculateIdealMetricsFromBMI(studentDatas.gender, regularPhysicalAssessment && regularPhysicalAssessment.height, studentDatas.dob).estimatedCaloricIntake,
          followupresult: followUpNutritionalAssessment && calculateIdealMetricsFromBMI(studentDatas.gender, followUpPhysicalAssessment && followUpPhysicalAssessment.height, studentDatas.dob).estimatedCaloricIntake,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },

        {

          "test_name": "Advice",
          regularresult: regularNutritionalAssessment && regularNutritionalAssessment.na_advice,
          followupresult: followUpNutritionalAssessment && followUpNutritionalAssessment.na_advice,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Remark",
          regularresult: regularNutritionalAssessment && regularNutritionalAssessment.na_remark,
          followupresult: followUpNutritionalAssessment && followUpNutritionalAssessment.na_remark,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },

      ])

      setGernalAssessment([
        {
          "test_name": "Chest Auscultation",
          regularresult: regularGaAssessment && regularGaAssessment.chest_auscultation,
          followupresult: followUpGaAssessment && followUpGaAssessment.chest_auscultation,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""
        },
        {

          "test_name": "Abdominal Palpitation",
          regularresult: regularGaAssessment && regularGaAssessment.abdominal_palpitation,
          followupresult: followUpGaAssessment && followUpGaAssessment.abdominal_palpitation,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Active Infectious Disease (If Any)",
          regularresult: regularGaAssessment && regularGaAssessment.active_infectious_disease_ifany,
          followupresult: followUpGaAssessment && followUpGaAssessment.active_infectious_disease_ifany,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Cleft Lip Palate",
          regularresult: regularGaAssessment && regularGaAssessment.cleft_lip_palate,
          followupresult: followUpGaAssessment && followUpGaAssessment.cleft_lip_palate,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Club Foot",
          regularresult: regularGaAssessment && regularGaAssessment.club_foot,
          followupresult: followUpGaAssessment && followUpGaAssessment.club_foot,
          reference: "",
          "classnameRegular": regularGaAssessment && regularGaAssessment.club_foot !== undefined ? regularGaAssessment.club_foot.toLowerCase() === "yes".toLowerCase() ? "redValue" : "" : "",
          "classnameFollowUp": followUpGaAssessment && followUpGaAssessment.club_foot !== undefined ? followUpGaAssessment.club_foot.toLowerCase() === "yes".toLowerCase() ? "redValue" : "" : ""

        },
        {

          "test_name": "Conginital Cataract",
          regularresult: regularGaAssessment && regularGaAssessment.conginital_cataract,
          followupresult: followUpGaAssessment && followUpGaAssessment.conginital_cataract,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Conginital Deafness",
          regularresult: regularGaAssessment && regularGaAssessment.conginital_deafness,
          followupresult: followUpGaAssessment && followUpGaAssessment.conginital_deafness,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Skin Infection (If Any)",
          regularresult: regularGaAssessment && regularGaAssessment.skin_infection_ifany,
          followupresult: followUpGaAssessment && followUpGaAssessment.skin_infection_ifany,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Otitis Media",
          regularresult: regularGaAssessment && regularGaAssessment.otitis_media,
          followupresult: followUpGaAssessment && followUpGaAssessment.otitis_media,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Neuromotor Impairment",
          regularresult: regularGaAssessment && regularGaAssessment.neuromotor_impairment,
          followupresult: followUpGaAssessment && followUpGaAssessment.neuromotor_impairment,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Body Type",
          regularresult: regularGaAssessment && regularGaAssessment.body_type,
          followupresult: followUpGaAssessment && followUpGaAssessment.body_type,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },


        {

          "test_name": "Advice",
          regularresult: regularGaAssessment && regularGaAssessment.ga_advice,
          followupresult: followUpGaAssessment && followUpGaAssessment.ga_advice,
          reference: ""

        },
        {

          "test_name": "Remark",
          regularresult: regularGaAssessment && !regularGaAssessment.ga_remark,
          followupresult: followUpGaAssessment && followUpGaAssessment.ga_remark,
          reference: ""

        },

      ])

      setDentalAssessment([
        {
          "test_name": "Dental Caries",
          regularresult: regularDentalAssessment && regularDentalAssessment.dental_caries,
          followupresult: followUpDentalAssessment && followUpDentalAssessment.dental_caries,
          reference: "",
          "classnameRegular": regularDentalAssessment && regularDentalAssessment.dental_caries !== undefined && regularDentalAssessment.dental_caries !== null ? regularDentalAssessment.dental_caries.toLowerCase() === "Advance".toLowerCase() || regularDentalAssessment.dental_caries.toLowerCase() === "moderate".toLowerCase() ? "redValue" : "" : "",
          "classnameFollowUp": followUpDentalAssessment && followUpDentalAssessment.dental_caries !== undefined && followUpDentalAssessment.dental_caries !== null ? followUpDentalAssessment.dental_caries.toLowerCase() === "Advance".toLowerCase() || followUpDentalAssessment.dental_caries.toLowerCase() === "moderate".toLowerCase() ? "redValue" : "" : ""
        },
        {

          "test_name": "Orthodontic Correction",
          regularresult: regularDentalAssessment && regularDentalAssessment.orthodontic_correction,
          followupresult: followUpDentalAssessment && followUpDentalAssessment.orthodontic_correction,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Extraction",
          regularresult: regularDentalAssessment && regularDentalAssessment.extraction,
          followupresult: followUpDentalAssessment && followUpDentalAssessment.extraction,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Space Maintainer",
          regularresult: regularDentalAssessment && regularDentalAssessment.space_maintainer,
          followupresult: followUpDentalAssessment && followUpDentalAssessment.space_maintainer,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },
        {

          "test_name": "Oral Hygiene Status",
          regularresult: regularDentalAssessment && regularDentalAssessment.oral_hygiene_status,
          followupresult: followUpDentalAssessment && followUpDentalAssessment.oral_hygiene_status,
          reference: "",
          "classnameRegular": !regularDentalAssessment && regularDentalAssessment.oral_hygiene_status !== undefined ? regularDentalAssessment.oral_hygiene_status.toLowerCase() === "poor".toLowerCase() ? "redValue" : "" : "",
          "classnameFollowUp": !followUpDentalAssessment && followUpDentalAssessment.oral_hygiene_status !== undefined ? followUpDentalAssessment.oral_hygiene_status.toLowerCase() === "poor".toLowerCase() ? "redValue" : "" : ""

        },
        {

          "test_name": "Teeth brushing in a Day",
          regularresult: regularDentalAssessment && regularDentalAssessment.number_of_teeth_brushing_in_a_day,
          followupresult: followUpDentalAssessment && followUpDentalAssessment.number_of_teeth_brushing_in_a_day,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""


        },
        {

          "test_name": "Malpositioned Tooth Teeth",
          regularresult: regularDentalAssessment && regularDentalAssessment.malpositioned_tooth_teeth,
          followupresult: followUpDentalAssessment && followUpDentalAssessment.malpositioned_tooth_teeth,
          reference: "",
          "classnameRegular": "",
          "classnameFollowUp": ""

        },



        {

          "test_name": "Advice",
          regularresult: regularDentalAssessment && regularDentalAssessment.ga_advice,
          followupresult: followUpDentalAssessment && followUpDentalAssessment.ga_advice,
          reference: ""

        },
        {

          "test_name": "Remark",
          regularresult: regularDentalAssessment && regularDentalAssessment.ga_remark,
          followupresult: followUpDentalAssessment && followUpDentalAssessment.ga_remark,
          reference: ""

        },

      ])

      setTimeout(() => {
        printAndDownloadPDF(studentIdr)
      }, 800);

    }


  }


  const printAndDownloadPDF = async (fileName) => {

    const html2pdf = require('html2pdf.js');
    const element = document.getElementById("pdf-container");
    const options = {
      margin: [11, 0, 20, 0] // [top, left, bottom, right]
    };
    await html2pdf()
      .set(options)
      .from(element)
      .save(fileName.toString() + '.pdf')
    setLoader(0)
    setStudentPdf(0)
    setCurrentRow("")

  };


  function calculateIdealMetricsFromBMI(gender, heightInInches, age) {
    console.log("gender", gender)
    if (gender !== undefined) {


      // Convert height to centimeters
      const heightInCm = heightInInches;
      // console.log("name",  gender, heightInInches, age, name)
      // Calculate the weight that corresponds to the given BMI
      // const weight = bmi * Math.pow(heightInCm / 100, 2);

      // Ideal Body Weight (IBW) calculation
      let ibw;
      if (gender.toLowerCase() === 'male') {
        ibw = 50 + (2.3 * ((heightInInches / 2.54) - 60));
      } else if (gender.toLowerCase() === 'female') {
        ibw = 45.5 + (2.3 * ((heightInInches / 2.54) - 60));
      }

      // Total Daily Energy Expenditure (TDEE) calculation
      const tdee = gender.toLowerCase() === 'male' ? (88.362 + (13.397 * ibw.toFixed(2)) + (4.799 * heightInCm) - (5.677 * calculateAge(age))) : (447.593 + (9.247 * ibw) + (3.098 * heightInCm) - (4.330 * calculateAge(age)));

      return {
        idealBodyWeight: !isNaN(ibw) ? ibw.toFixed(2) : "-",
        estimatedCaloricIntake: !isNaN(tdee) ? Math.abs(parseInt(tdee.toFixed(2))) : "-",
      };
    }
  }
  useEffect(() => {

    const localUrl = window.sessionStorage.getItem("userData")?JSON.parse(window.sessionStorage.getItem("userData")).responseValue.imagePath.replaceAll("\\", "/"):"";
    // const localURL = "https://api.stufithealth.app/Upload/Image/1713857617198_clientlogo.png";
    // setClientLogo(localURL)
    ToDataURL(localUrl, function(dataURL){
      setClientLogo(dataURL)

   })
    getData()
  }, [])

  return (
    <div className='pdf-container ' id="pdf-container">
      <div className='pdf-header_ d-flex flex-row justify-content-between align-items-center'>
        <img src={clientLogo} alt='clientLogo' className='pdf-clientlogo' />
        <div className='d-flex flex-column gap-3'>
          <label className='pdf-heading-top'>School Health Program</label>
          <small>An Initiative By Lucknow Smart City Limited</small>
          <small style={{ fontSize: "14px", fontWeight: "600" }}>Health Report</small>
        </div>

        <img src={govermentlogo} alt='userlogo' className='pdf-userlogo ' />
      </div>

      <div className='student-info-container  pt-5'>
        <div className='personal-info-container'>
          <label className='label-heading'>Personal Details</label>
          <table className='table   table-borderless'>
            {
              studentDatas && studentDatas.map((val, ind) => {
                return (
                  <tr>
                    <td className='w-25'>
                      <div className='d-flex flex-column gap-1'>
                        <label className='info-heading'>{val.name1}</label>
                        <label className='info-info'>{val.value1 !== "" ? val.value1 : "-"}</label>
                      </div>
                    </td>
                    <td className='w-25'>
                      <div className='d-flex flex-column gap-1'>
                        <label className='info-heading'>{val.name2}</label>
                        <label className='info-info'>{val.value2 !== "" ? val.value2 : "-"}</label>
                      </div>
                    </td>
                    <td className='w-25'>
                      <div className='d-flex flex-column gap-1'>
                        <label className='info-heading'>{val.name3}</label>
                        <label className='info-info'>{val.value3 !== "" ? val.value3 : "-"}</label>
                      </div>
                    </td>
                    <td className='w-25'>
                      <div className='d-flex flex-column gap-1'>
                        <label className='info-heading'>{val.name4}</label>
                        <label className='info-info'>{val.value4 !== "" ? val.value4 : "-"}</label>
                      </div>
                    </td>
                  </tr>
                )

              })

            }

          </table>
        </div>
        <hr className='underline' />

        <div className='mt-0'>
          <label className='label-heading'>Physical Assessment</label>
          <table className='table table-sm   table-borderless mt-2'>
            <thead >
              <tr>
                <th className='tb-heading'>Parameters</th>
                <th className='tb-heading'>Result (First Assessment)</th>
                <th className='tb-heading'>Result (Second Assessment)</th>
                <th className='tb-heading'>Reference</th>
              </tr>
            </thead>
            <tbody>
              {
                physicalAssessment && physicalAssessment.map((val, ind) => {
                  if (val.test_name !== "Advice" && val.test_name !== "Remark") {
                    return (

                      <tr>
                        <td className={`tb-row ${ind % 2 !== 0 ? "stripedrow" : ""}`}>{val.test_name}</td>
                        <td className={`tb-row ${ind % 2 !== 0 ? "stripedrow" : ""} ${val.classnameRegular}`}>{val.regularresult ? val.regularresult + val.unit : "-"} </td>
                        <td className={`tb-row ${ind % 2 !== 0 ? "stripedrow" : ""} ${val.classnameFollowUp}`}>{val.followupresult ? val.followupresult + val.unit : "-"} </td>
                        <td className={`tb-row ${ind % 2 !== 0 ? "stripedrow" : ""}`}>{val.reference ? val.reference + val.unit : "-"}</td>
                      </tr>
                    )
                  }
                })
              }
            </tbody>
          </table>
          <div>
            <div className='d-flex flex-column gap-1 advice'>
              <label className='advice-heading  fw-bold'>Current Advice</label>
              <label className='advice-data'>
                {physicalAssessment && physicalAssessment.map((val, ind) => {
                  if (val.test_name === "Advice") {
                    console.log("dddddddddddddddddddd", val.followupresult !== undefined ? val.followupresult : val.regularresult)
                    return (
                      val.followupresult !== undefined ? val.followupresult : val.regularresult
                    )
                  }
                })}
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent venenatis metus vitae arcu iaculis, vel aliquam diam efficitur. Vestibulum sit amet ligula ipsum. */}
              </label>
            </div>
            <div className='d-flex flex-column gap-1 advice mt-2'>
              <label className='advice-heading fw-bold'>Current Remark</label>
              <label className='advice-data'>
                {physicalAssessment && physicalAssessment.map((val, ind) => {
                  if (val.test_name === "Remark") {
                    return (
                      val.followupresult !== undefined ? val.followupresult : val.regularresult
                    )
                  }
                })}
              </label>
            </div>
          </div>
        </div>

        <hr className='underline' />
        <div className='mt-0'>
          <label className='label-heading'>Eye And Vision Assessment</label>
          <table className='table table-sm   table-borderless mt-1'>
            <thead >
              <tr>
                <th className='tb-heading'>Parameters</th>
                <th className='tb-heading'>Result (First Assessment)</th>
                <th className='tb-heading'>Result (Second Assessment)</th>
                <th className='tb-heading'>Reference</th>
              </tr>
            </thead>
            <tbody>
              {
                visionAssessment && visionAssessment.map((val, ind) => {
                  if (val.test_name !== "Advice" && val.test_name !== "Remark") {
                    return (

                      <tr>
                        <td className={`tb-row ${ind % 2 !== 0 ? "stripedrow" : ""}`}>{toCamelCase(val.test_name)}</td>
                        <td className={`tb-row ${ind % 2 !== 0 ? "stripedrow" : ""} ${val.classnameRegular}`}>{val.regularresult ? toCamelCase(val.regularresult) : "-"} {val.unit}</td>
                        <td className={`tb-row ${ind % 2 !== 0 ? "stripedrow" : ""} ${val.classnameFollowUp}`}>{val.followupresult ? toCamelCase(val.followupresult) : "-"} {val.unit}</td>
                        <td className={`tb-row ${ind % 2 !== 0 ? "stripedrow" : ""}`}>{val.reference ? val.reference : "-"}</td>
                      </tr>
                    )
                  }
                })
              }
            </tbody>
          </table>
          <div>
            <div className='d-flex flex-column gap-1 advice'>
              <label className='advice-heading  fw-bold'>Current Advice</label>
              <label className='advice-data'>
                {visionAssessment && visionAssessment.map((val, ind) => {
                  if (val.test_name === "Advice") {
                    return (
                      val.followupresult !== undefined ? val.followupresult : val.regularresult
                    )
                  }
                })}
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent venenatis metus vitae arcu iaculis, vel aliquam diam efficitur. Vestibulum sit amet ligula ipsum. */}
              </label>
            </div>
            <div className='d-flex flex-column gap-1 advice mt-2'>
              <label className='advice-heading fw-bold'>Current Remark</label>
              <label className='advice-data'>
                {visionAssessment && visionAssessment.map((val, ind) => {
                  if (val.test_name === "Remark") {
                    return (
                      val.followupresult !== undefined ? val.followupresult : val.regularresult
                    )
                  }
                })}
              </label>
            </div>
          </div>
        </div>


        <hr className='underline' />
        <div className='mt-2_ pt-3'>
          <label className='label-heading'>Audio & Speech Assessment</label>
          <table className='table table-sm   table-borderless mt-1_'>
            <thead >
              <tr>
                <th className='tb-heading'>Parameters</th>
                <th className='tb-heading'>Result (First Assessment)</th>
                <th className='tb-heading'>Result (Second Assessment)</th>
                <th className='tb-heading'>Reference</th>
              </tr>
            </thead>
            <tbody>
              {
                soundAssessment && soundAssessment.map((val, ind) => {
                  if (val.test_name !== "Advice" && val.test_name !== "Remark") {
                    return (

                      <tr>
                        <td className={`tb-row ${ind % 2 !== 0 ? "stripedrow" : ""}`}>{toCamelCase(val.test_name)}</td>
                        <td className={`tb-row ${ind % 2 !== 0 ? "stripedrow" : ""}${val.classnameRegular}`}>{val.regularresult ? toCamelCase(val.regularresult) : "-"} {val.unit}</td>
                        <td className={`tb-row ${ind % 2 !== 0 ? "stripedrow" : ""}${val.classnameFollowUp}`}>{val.followupresult ? toCamelCase(val.followupresult) : "-"} {val.unit}</td>
                        <td className={`tb-row ${ind % 2 !== 0 ? "stripedrow" : ""}`}>{val.reference ? val.reference : "-"}</td>
                      </tr>
                    )
                  }
                })
              }
            </tbody>
          </table>
          <div>
            <div className='d-flex flex-column gap-1 advice'>
              <label className='advice-heading  fw-bold'>Current Advice</label>
              <label className='advice-data'>
                {soundAssessment && soundAssessment.map((val, ind) => {
                  if (val.test_name === "Advice") {
                    return (
                      val.followupresult !== undefined ? val.followupresult : val.regularresult
                    )
                  }
                })}
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent venenatis metus vitae arcu iaculis, vel aliquam diam efficitur. Vestibulum sit amet ligula ipsum. */}
              </label>
            </div>
            <div className='d-flex flex-column gap-1 advice mt-2'>
              <label className='advice-heading fw-bold'>Current Remark</label>
              <label className='advice-data'>
                {physicalAssessment && physicalAssessment.map((val, ind) => {
                  if (val.test_name === "Remark") {
                    return (
                      val.followupresult !== undefined ? val.followupresult : val.regularresult
                    )
                  }
                })}
              </label>
            </div>
          </div>
        </div>


        <hr className='underline' />
        <div className='mt-2'>
          <label className='label-heading'>Nutritional Assessment</label>
          <table className='table table-sm   table-borderless mt-2'>
            <thead >
              <tr>
                <th className='tb-heading'>Parameters</th>
                <th className='tb-heading'>Result (First Assessment)</th>
                <th className='tb-heading'>Result (Second Assessment)</th>
                <th className='tb-heading'>Reference</th>
              </tr>
            </thead>
            <tbody>
              {
                nutritionalAssessment && nutritionalAssessment.map((val, ind) => {
                  if (val.test_name !== "Advice" && val.test_name !== "Remark") {
                    return (

                      <tr>
                        <td className={`tb-row ${ind % 2 !== 0 ? "stripedrow" : ""}`}>{toCamelCase(val.test_name)}</td>
                        <td className={`tb-row ${ind % 2 !== 0 ? "stripedrow" : ""} ${val.classnameRegular}`}>{val.regularresult ? toCamelCase(val.regularresult) : "-"} {val.unit}</td>
                        <td className={`tb-row ${ind % 2 !== 0 ? "stripedrow" : ""} ${val.classnameFollowUp}`}>{val.followupresult ? toCamelCase(val.followupresult) : "-"} {val.unit}</td>
                        <td className={`tb-row ${ind % 2 !== 0 ? "stripedrow" : ""}`}>{val.reference ? val.reference : "-"}</td>
                      </tr>
                    )
                  }
                })
              }
            </tbody>
          </table>
          <div>
            <div className='d-flex flex-column gap-1 advice'>
              <label className='advice-heading  fw-bold'>Current Advice</label>
              <label className='advice-data'>
                {nutritionalAssessment && nutritionalAssessment.map((val, ind) => {
                  if (val.test_name === "Advice") {
                    return (
                      val.followupresult !== undefined ? val.followupresult : val.regularresult
                    )
                  }
                })}
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent venenatis metus vitae arcu iaculis, vel aliquam diam efficitur. Vestibulum sit amet ligula ipsum. */}
              </label>
            </div>
            <div className='d-flex flex-column gap-1 advice mt-2'>
              <label className='advice-heading fw-bold'>Current Remark</label>
              <label className='advice-data'>
                {nutritionalAssessment && nutritionalAssessment.map((val, ind) => {
                  if (val.test_name === "Remark") {
                    return (
                      val.followupresult !== undefined ? val.followupresult : val.regularresult
                    )
                  }
                })}
              </label>
            </div>
          </div>
        </div>

        <hr className='underline' />
        <div className='mt-2_ pt-5'>
          <label className='label-heading'>Dental Assessment</label>
          <table className='table table-sm   table-borderless mt-3'>
            <thead >
              <tr>
                <th className='tb-heading'>Parameters</th>
                <th className='tb-heading'>Result (First Assessment)</th>
                <th className='tb-heading'>Result (Second Assessment)</th>
                <th className='tb-heading'>Reference</th>
              </tr>
            </thead>
            <tbody>
              {
                dentalAssessment && dentalAssessment.map((val, ind) => {
                  if (val.test_name !== "Advice" && val.test_name !== "Remark") {
                    return (

                      <tr>
                        <td className={`tb-row ${ind % 2 !== 0 ? "stripedrow" : ""}`}>{toCamelCase(toCamelCase(val.test_name))}</td>
                        <td className={`tb-row ${ind % 2 !== 0 ? "stripedrow" : ""} ${val.classnameRegular}`}>{val.regularresult ? toCamelCase(val.regularresult) : "-"} {val.unit}</td>
                        <td className={`tb-row ${ind % 2 !== 0 ? "stripedrow" : ""} ${val.classnameFollowUp}`}>{val.followupresult ? toCamelCase(val.followupresult) : "-"} {val.unit}</td>
                        <td className={`tb-row ${ind % 2 !== 0 ? "stripedrow" : ""}`}>{val.reference ? val.reference : "-"}</td>
                      </tr>
                    )
                  }
                })
              }
            </tbody>
          </table>
          <div>
            <div className='d-flex flex-column gap-1 advice'>
              <label className='advice-heading  fw-bold'>Current Advice</label>
              <label className='advice-data'>
                {dentalAssessment && dentalAssessment.map((val, ind) => {
                  if (val.test_name === "Advice") {
                    return (
                      val.followupresult !== undefined ? val.followupresult : val.regularresult
                    )
                  }
                })}
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent venenatis metus vitae arcu iaculis, vel aliquam diam efficitur. Vestibulum sit amet ligula ipsum. */}
              </label>
            </div>
            <div className='d-flex flex-column gap-1 advice mt-2'>
              <label className='advice-heading fw-bold'>Current Remark</label>
              <label className='advice-data'>
                {dentalAssessment && dentalAssessment.map((val, ind) => {
                  if (val.test_name === "Remark") {
                    return (
                      val.followupresult !== undefined ? val.followupresult : val.regularresult
                    )
                  }
                })}
              </label>
            </div>
          </div>
        </div>

        <hr className='underline' />
        <div className='mt-2'>
          <label className='label-heading'>General Assessment</label>
          <table className='table table-sm   table-borderless mt-2'>
            <thead >
              <tr>
                <th className='tb-heading'>Parameter</th>
                <th className='tb-heading'>Result (First Assessment)</th>
                <th className='tb-heading'>Result (Second Assessment)</th>
                <th className='tb-heading'>Reference</th>
              </tr>
            </thead>
            <tbody>
              {
                gernalAssessment && gernalAssessment.map((val, ind) => {
                  if (val.test_name !== "Advice" && val.test_name !== "Remark") {
                    return (

                      <tr>
                        <td className={`tb-row ${ind % 2 !== 0 ? "stripedrow" : ""}`}>{toCamelCase(val.test_name)}</td>
                        <td className={`tb-row ${ind % 2 !== 0 ? "stripedrow" : ""} ${val.classnameRegular}`}>{val.regularresult ? toCamelCase(val.regularresult) : "-"} {val.unit}</td>
                        <td className={`tb-row ${ind % 2 !== 0 ? "stripedrow" : ""} ${val.classnameFollowUp}`}>{val.followupresult ? toCamelCase(val.followupresult) : "-"} {val.unit}</td>
                        <td className={`tb-row ${ind % 2 !== 0 ? "stripedrow" : ""}`}>{val.reference ? val.reference : "-"}</td>
                      </tr>
                    )
                  }
                })
              }
            </tbody>
          </table>
          <div>
            <div className='d-flex flex-column gap-1 advice'>
              <label className='advice-heading  fw-bold'>Current Advice</label>
              <label className='advice-data'>
                {gernalAssessment && gernalAssessment.map((val, ind) => {
                  if (val.test_name === "Advice") {
                    return (
                      val.followupresult !== undefined ? val.followupresult : val.regularresult
                    )
                  }
                })}
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent venenatis metus vitae arcu iaculis, vel aliquam diam efficitur. Vestibulum sit amet ligula ipsum. */}
              </label>
            </div>
            <div className='d-flex flex-column gap-1 advice mt-2'>
              <label className='advice-heading fw-bold'>Current Remark</label>
              <label className='advice-data'>
                {gernalAssessment && gernalAssessment.map((val, ind) => {
                  if (val.test_name === "Remark") {
                    return (
                      val.followupresult !== undefined ? val.followupresult : val.regularresult
                    )
                  }
                })}
              </label>
            </div>
          </div>
          <div className='disclaimer d-flex flex-column gap-3 pt-2 mt-3'>
            <label className='text-danger'><strong>Disclaimer: </strong></label>
            <label className='text-wrap ' style={{ "line-height": "1.5" }}>This report is not intended to be a tell consultation blood group. Stufit Approach aims to provide only the health care screening and wellness part as our public health endeavors. For any medical condition you may concern</label>
          </div>
        </div>

      </div>
    </div>
  )
}
